<template>
  <div class="card pt-2">
    <div class="flex flex-wrap sm:justify-content-end justify-content-center align-items-center pb-2">
      <div class="mr-3">
        <FilterSearchButton v-model="filters['global'].value" />
      </div>
      <div class="mr-3">
        <Button
          label="New Recurring"
          icon="las la-plus"
          @click="$store.dispatch('setSliderView', 'RecurrenceSlider')"
        />
      </div>
    </div>

    <div>
      <DataTable
        responsiveLayout="scroll"
        :rowClass="rowClass"
        :value="templates"
        editMode="row"
        :filters.sync="filters"
        filterDisplay="menu"
        dataKey="uid"
        :editingRows.sync="editingRows"
        @row-edit-cancel="onRowEditCancel"
        @row-edit-save="saveRow"
        :paginator="true"
        :rows="30"
        :rowsPerPageOptions="[30, 50, 100]"
      >
        <template #empty>
          <Button
            v-if="!filters['global'].value"
            label="New Recurring"
            class="p-button-text p-button-secondary"
            icon="las la-plus"
            @click="$store.dispatch('setSliderView', 'RecurrenceSlider')"
          />
          <div v-else>No Results</div>
        </template>

        <!-- Job Name Column -->
        <Column
          field="job_name"
          sortable
          header="Job Name"
        >
          <template #body="slotProps">
            <span
              class="cursor-pointer"
              @click="
                $store.dispatch('setSelectedObject', slotProps.data);
                $store.dispatch('setSliderView', 'RecurrenceSlider');
              "
            >
              {{ slotProps.data.job_name }}
            </span>
          </template>
          <template #editor="slotProps">
            <InputText
              placeholder="Job Name"
              v-model="slotProps.data.job_name"
            />
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by Job Name"
            />
          </template>
        </Column>

        <!-- Job Template Column -->
        <Column
          field="job_template"
          sortable
          header="Job Template"
        >
          <template #body="slotProps">
            {{ slotProps.data.job_template }}
          </template>
          <template #editor="slotProps">
            <InputText
              placeholder="Job Template"
              v-model="slotProps.data.job_template"
            />
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by Job Template"
            />
          </template>
        </Column>

        <!-- Repeat Every Column -->
        <Column
          field="repeat_every"
          sortable
          header="Repeat Every"
        >
          <template #body="slotProps">
            {{ slotProps.data.repeat_every }}
          </template>
          <template #editor="slotProps">
            <InputText
              placeholder="Repeat Every"
              v-model="slotProps.data.repeat_every"
            />
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by Repeat Every"
            />
          </template>
        </Column>

        <!-- Until Column -->
        <Column
          field="until"
          sortable
          header="Until"
        >
          <template #body="slotProps">
            {{ slotProps.data.until }}
          </template>
          <template #editor="slotProps">
            <InputText
              placeholder="Until"
              v-model="slotProps.data.until"
            />
          </template>
          <template #filter="{ filterModel }">
            <InputText
              type="text"
              v-model="filterModel.value"
              class="p-column-filter"
              placeholder="Search by Until"
            />
          </template>
        </Column>

        <!-- Active Column -->
        <Column
          sortable
          field="active"
          header="Active"
        >
          <template #body="slotProps">
            <AActiveSwitch v-model="slotProps.data.active" />
          </template>
        </Column>

        <!-- Action Column -->
        <Column bodyStyle="text-align:right">
          <template #body="slotProps">
            <template v-if="editingRow !== slotProps.data.uid">
              <div class="flex">
                <Button
                  icon="las la-pen i-20"
                  class="p-button-md p-button-rounded grey-button mr-3"
                  @click="editRow(slotProps.data, templates)"
                />
                <Button
                  icon=" las la-trash i-20"
                  class="p-button-md p-button-rounded grey-button"
                />
              </div>
            </template>
            <template v-else>
              <div class="flex">
                <Button
                  icon="las la-check i-20"
                  class="p-button-md p-button-rounded grey-button mr-3"
                  @click="saveRow(slotProps)"
                />
                <Button
                  icon=" las la-times i-20"
                  class="p-button-md p-button-rounded grey-button"
                  @click="onRowEditCancel(slotProps.data, templates)"
                />
              </div>
            </template>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { state, loadData } from "../../../services/data_service.js";
import { FilterMatchMode } from "primevue/api";

export default {
  data() {
    return {
      drag: false,
      originalRows: [],
      editingRows: [],
      editingRow: null,
      templates: [], // Ensure this matches your data source
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        job_name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        job_template: { value: null, matchMode: FilterMatchMode.CONTAINS },
        repeat_every: { value: null, matchMode: FilterMatchMode.CONTAINS },
        until: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },

  methods: {
    rowClass() {
      return "row-accessories";
    },

    editRow(r, arr) {
      this.editingRow = r.uid;
      this.editingRows.push(r);
      var index = arr.indexOf(r);
      this.originalRows[index] = {
        ...arr[index],
      };
    },
    saveRow(r) {
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API + "/v1/templates/" + r.uid,
          JSON.stringify(r),
          {
            headers: { "Content-type": "application/json" },
          }
        )
        .then((response) => {
          this.editingRow = null;
          var i2 = this.editingRows.indexOf(r);
          this.editingRows.splice(i2, 1);
          this.$toast.add({
            severity: "success",
            summary: "Recurring Updated",
            life: 3000,
          });

          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.error(error);
        });
    },

    onRowEditCancel(r, arr) {
      var index = arr.indexOf(r);
      this.$set(arr, index, this.originalRows[index]);
      this.editingRow = null;
      var i2 = this.editingRows.indexOf(r);
      this.editingRows.splice(i2, 1);
    },
  },

  metaInfo: {
    title: "Settings | Recurring",

    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "" },
      { name: "keywords", content: "" },
    ],
  },
};
</script>
