<template>
  <div class="card col-12 pt-3">
    <!-- Filters Section -->
    <div class="flex flex-wrap justify-content-between align-items-center mb-3">
      <div class="flex">
        <!-- Dropdown Filters -->
        <Dropdown
          class="mr-3"
          :options="accountManagers"
          optionLabel="label"
          optionValue="value"
          v-model="selectedAccountManager"
          placeholder="Account Manager"
        />
        <Dropdown
          class="mr-3"
          :options="includeOptions"
          style="width: 150px;"
          optionLabel="label"
          optionValue="value"
          v-model="selectedIncludeOption"
          placeholder="Include"
        />
        <!-- Checkbox -->
        <div class="flex align-items-center">

          <label for="groupByClient">Group by Client</label>
          <Checkbox
            v-model="groupByClient"
            class="ml-2"
          />
        </div>
      </div>
      <!-- Build Report Button -->
      <Button
        label="Build Report"
        class="p-button-primary"
        @click="buildReport"
      />
    </div>

    <!-- Data Table -->
    <DataTable
      :value="jobs"
      responsiveLayout="scroll"
      :rowClass="rowClass"
      dataKey="uid"
      filterDisplay="menu"
      :filters.sync="filters"
      paginator
      :rows="30"
      :rowsPerPageOptions="[30, 50, 100]"
      emptyMessage="No data to display"
    >
      <!-- Columns -->
      <Column
        sortable
        header="No"
        field="job_number"
        :styles="{ width: '10%' }"
      >
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Search by job number"
          />
        </template>
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="50%"
          ></Skeleton>
          <template v-else>
            <div
              @click="navigateToJob(slotProps)"
              class="cursor-pointer"
            >
              {{ slotProps.data.job_number ?? "N/A" }}
            </div>
          </template>
        </template>
      </Column>

      <Column
        sortable
        header="Name"
        field="name"
        :styles="{ width: '20%' }"
      >
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Search by name"
          />
        </template>
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="50%"
          ></Skeleton>
          <template v-else>
            <div
              @click="navigateToJob(slotProps)"
              class="cursor-pointer"
            >
              {{ slotProps.data.name ?? "N/A" }}
            </div>
          </template>
        </template>
      </Column>

      <Column
        sortable
        header="AE"
        field="account_executive"
        :styles="{ width: '15%' }"
      >
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Search by AE"
          />
        </template>
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="50%"
          ></Skeleton>
          <template v-else>
            {{ extractFirstName(slotProps.data.account_executive) ?? "N/A" }}
          </template>
        </template>
      </Column>

      <Column
        sortable
        header="Status"
        field="status"
        :styles="{ width: '15%' }"
      >
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Search by status"
          />
        </template>
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="50%"
          ></Skeleton>
          <template v-else>
            {{ slotProps.data.status ?? "N/A" }}
          </template>
        </template>
      </Column>

      <Column
        sortable
        header="Created"
        field="created_date"
        dataType="date"
        :styles="{ width: '15%' }"
      >
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="50%"
          ></Skeleton>
          <template v-else>
            {{ slotProps.data.created_date | moment("DD MMM YYYY") }}
          </template>
        </template>
      </Column>

      <Column
        sortable
        header="Time"
        field="time_spent"
        :styles="{ width: '10%' }"
      >
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="50%"
          ></Skeleton>
          <template v-else>
            {{ slotProps.data.time_spent ?? "N/A" }}
          </template>
        </template>
      </Column>

      <Column
        sortable
        header="Total Incl."
        field="total_incl"
        :styles="{ width: '15%' }"
      >
        <template #body="slotProps">
          <Skeleton
            v-if="isLoading"
            width="50%"
          ></Skeleton>
          <template v-else>
            {{ formatCurrency(slotProps.data.total_incl) }}
          </template>
        </template>
      </Column>
    </DataTable>

  </div>
</template>

<script>
export default {
  name: "ActiveJobs",
  data() {
    return {
      selectedAccountManager: null,
      selectedIncludeOption: null,
      groupByClient: false,
      filters: {
        global: { value: null, matchMode: "contains" },
      },
      jobs: [], // Jobs data
      accountManagers: [
        { label: "Manager 1", value: "manager1" },
        { label: "Manager 2", value: "manager2" },
      ],
      includeOptions: [
        { label: "Option 1", value: "option1" },
        { label: "Option 2", value: "option2" },
      ],
    };
  },
  methods: {
    buildReport() {
      // Logic to filter and fetch report data
      console.log("Building report with filters", {
        accountManager: this.selectedAccountManager,
        includeOption: this.selectedIncludeOption,
        groupByClient: this.groupByClient,
      });
      // Simulated data fetch
      this.jobs = [
        {
          no: 1,
          name: "Job A",
          accountExecutive: "AE1",
          status: "Open",
          createdDate: "2024-11-01",
          timeSpent: "3h",
          totalIncl: "$300",
        },
        {
          no: 2,
          name: "Job B",
          accountExecutive: "AE2",
          status: "Closed",
          createdDate: "2024-11-02",
          timeSpent: "4h",
          totalIncl: "$400",
        },
      ];
    },
    rowClass() {
      return "row-accessories row-accessories2";
    },
    navigateToJob(slotProps) {
      const route = `/job/${slotProps.data.uid}`;
      this.$router.push(route);
    },
    extractFirstName(name) {
      return name?.split(" ")[0] ?? "N/A";
    },
  },
};
</script>

<style scoped>
/* Adjust styles for cleaner UI and match the shared design */
.flex {
  display: flex;
}
.mb-3 {
  margin-bottom: 1rem;
}
.mr-3 {
  margin-right: 1rem;
}
.align-items-center {
  align-items: center;
}
.p-button-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}
</style>
