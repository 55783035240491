<template>
  <div>
    <form
      @submit.prevent="submitHandler"
      v-form-enter
    >
      <div class="agencydesk-spacer" />

      <div class="p-fluid formgrid grid">
        <div class="col-12 md:col-6">
          <div class="p-fluid formgrid grid justify-content-around">
            <div class="field col-12">
              <h2>General</h2>
            </div>
            <div class="field col-12">
              <label for="name">Company Name</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeholder="Company Name"
                  v-model="client.company_name"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.client.company_name.required"
              >
                Company name is required
              </div>
            </div>

            <div class="field col-12 sm:col-6">
              <label>Client Code</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  v-model="client.client_code"
                  placeholder="Client code"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.client.client_code.required"
              >
                Client code is required
              </div>
            </div>
            <div class="field col-12 sm:col-6 flex align-items-center">
              <div class="p-inputgroup flex align-items-center">
                <Checkbox
                  v-if="!isLoading"
                  id="binary"
                  class="pt-2"
                  v-model="client.active"
                  :binary="true"
                  :trueValue="1"
                  :falseValue="0"
                />
                <Skeleton
                  v-else
                  width="2rem"
                  height="2rem"
                  borderRadius="6px"
                ></Skeleton>
                <label
                  v-if="!isLoading"
                  class="font-light ml-3 pt-3"
                >Account Active</label>
                <Skeleton
                  v-else
                  class="ml-1 mt-1"
                  width="30%"
                  borderRadius="6px"
                ></Skeleton>
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label for="name">Default Account Executive</label>
              <div class="p-inputgroup">
                <Dropdown
                  v-if="!isLoading"
                  :options="users"
                  optionLabel="name"
                  optionValue="uid"
                  placeholder="Select Account Executive"
                  class="input"
                  v-model="client.account_executive_uid"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.client.account_executive_uid.required"
              >
                Account executive is required
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label for="name">Telephone</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  v-model="client.telephone_number"
                  placeholder="Telephone"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <!-- <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.client.telephone_number.required"
              >
                Telephone number is required
              </div> -->
              <!-- <div
                class="error-validation-text pt-1"
                v-if="!$v.client.telephone_number.integer"
              >
                Only enter numeric values
              </div> -->
              <!-- <div
                class="error-validation-text pt-1"
                v-if="!$v.client.telephone_number.minLength"
              >
                Telephone number must be at least
                {{ $v.client.telephone_number.$params.minLength.min }} digits
              </div> -->
              <!-- <div
                class="error-validation-text pt-1"
                v-if="!$v.client.telephone_number.maxLength"
              >
                Telephone number must not be more than
                {{ $v.client.telephone_number.$params.maxLength.max }} digits
              </div> -->
            </div>
            <div class="field col-12 sm:col-6">
              <label class="label">Email</label>
              <div class="p-inputgroup">
                <InputText
                  v-model="client.email"
                  placeholder="Email"
                  v-if="!isLoading"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.client.email.required"
              >
                Email is required
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="!$v.client.email.email"
              >
                Please enter a valid email
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label class="label">Website</label>
              <div class="p-inputgroup">
                <InputText
                  v-model="client.website"
                  placeholder="Website"
                  v-if="!isLoading"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <!-- <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.client.website.required"
              >
                Website is required
              </div> -->
            </div>
            <div class="field col-12 sm:col-6">
              <label
                clas="font-light mb-3"
                for="name"
              >Physical Address</label>
              <div>
                <Textarea
                  v-if="!isLoading"
                  id="Description"
                  placeholder="Physical Address"
                  :autoResize="true"
                  rows="5"
                  @input="
                    syncAddress();
                    isDirty = true;
                  "
                  v-model="client.physical_address"
                />
                <Skeleton
                  height="108px"
                  borderRadius="6px"
                  v-else
                />
              </div>
            </div>

            <div class="field col-12 sm:col-6 pl-0 pr-0">
              <div class="font-light mb-1 ml-2 flex align-items-center justify-content-between">

                <label
                  clas="font-light mb-3"
                  for="name"
                >Postal Address</label>
                <div class="flex align-items-center">
                  <Checkbox
                    id="same"
                    v-model="sameAddress"
                    :binary="true"
                  />
                  <label
                    for="same"
                    class="pl-2 text-sm pr-2"
                  >Same as Physical</label>
                </div>
              </div>
              <div class="field col-12">
                <Textarea
                  v-if="!isLoading"
                  id="Description"
                  placeholder="Postal Address"
                  :autoResize="true"
                  rows="5"
                  :disabled="sameAddress === true"
                  v-model="client.postal_address"
                />
                <Skeleton
                  height="108px"
                  borderRadius="6px"
                  v-else
                />
              </div>
            </div>
            <!-- <div class="field col-12 sm:col-6">
              <label for="name">Physical Address</label>
              <div>
                <Textarea
                  id="Description"
                  v-if="!isLoading"
                  :autoResize="true"
                  rows="5"
                  v-model="client.physical_address"
                />
                <Skeleton height="108px" borderRadius="6px" v-else />
              </div>
            </div>

            <div class="field col-12 sm:col-6">
              <label>Postal Address</label>

              <div>
                <Textarea
                  :autoResize="true"
                  v-if="!isLoading"
                  rows="5"
                  v-model="client.postal_address"
                />
                <Skeleton height="108px" borderRadius="6px" v-else />
              </div>
            </div> -->

            <Divider class="m-2 mb-5"></Divider>
            <div class="field col-12">
              <h2>Financial</h2>
            </div>
            <div class="field col-12 sm:col-6">
              <label>Registered Company Name</label>
              <div class="p-inputgroup">
                <InputText
                  v-model="client.display_name"
                  v-if="!isLoading"
                  placeholder="Registered Company Name"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.client.display_name.required"
              >
                Company name display is required
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label>Company Registration No.</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  v-model="client.company_registration"
                  placeholder="Company Registration No"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label class="label">Tax / VAT Number</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeholder="Tax Number"
                  v-model="client.tax_number"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <!-- <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.client.tax_number.required"
              >
                Tax number is required
              </div> -->
            </div>
            <div class="field col-12 sm:col-6">
              <label class="label">Tax / VAT Rates (%)</label>
              <div class="p-inputgroup">
                <InputNumber
                  v-if="!isLoading"
                  :minFractionDigits="2"
                  locale="en-US"
                  placeHolder="Tax / VAT Rates (%)"
                  v-model="client.tax_rate"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.client.tax_rate.required"
              >
                Tax rate is required
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label class="label">Billing Tier</label>
              <div class="p-inputgroup">
                <Dropdown
                  v-if="!isLoading"
                  :options="newbillingTiers"
                  @change="selectCurrency(client.billing_tier_uid)"
                  optionLabel="name"
                  optionValue="uid"
                  placeholder="Billing Tier"
                  class="input"
                  v-model="client.billing_tier_uid"
                />

                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.client.billing_tier_uid.required"
              >
                Billing tier is required
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label class="label">Currency</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeholder="Currency"
                  v-model="currency"
                  readonly
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label class="label">Payment Terms </label>
              <div class="p-inputgroup">
                <Dropdown
                  v-if="!isLoading"
                  :options="clientTerms"
                  optionLabel="description"
                  optionValue="uid"
                  placeholder="Select Payment Terms"
                  class="input"
                  v-model="client.client_terms_uid"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <!-- <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.client.client_terms_uid.required"
              >
                Payment terms are required
              </div> -->
            </div>
            <div class="field col-12 sm:col-6">
              <label class="label">Credit Limit</label>
              <div class="p-inputgroup">
                <span
                  v-if="!isLoading"
                  class="p-inputgroup-addon"
                >{{
                  currencySymbol
                }}</span>
                <InputNumber
                  v-if="!isLoading"
                  :minFractionDigits="2"
                  locale="en-US"
                  placeholder="Credit limit"
                  class="input"
                  v-model="client.client_credit"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <!-- <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.client.client_credit.required"
              >
                Client Credit is required
              </div> -->
            </div>
          </div>
        </div>
        <div
          v-if="$route.params.uid"
          class="col-12 md:col-4 ml-6"
        >
          <div class="ml-3">
            <h2>Contacts</h2>
          </div>
          <Divider class="m-2"></Divider>
          <template>
            <div
              v-for="contact in client.contacts"
              :key="contact.uid"
            >
              <Skeleton
                class="ml-3 my-3"
                width="50%"
                v-if="isLoading"
              ></Skeleton>
              <div
                v-if="contact"
                @mouseenter="mouseenter(contact.uid)"
                @mouseleave="mouseleave"
                style="height: 20px"
                class="ml-3 my-3 cursor-pointer flex justify-content-between"
              >
                <div class="flex align-items-center">
                  <span>
                    <Button
                      v-model="contact.is_primary"
                      icon="lar la-star i-20"
                      :class="
                        'p-button-sm p-button-rounded h-2rem w-2rem p-0 mr-3 cursor-pointer ' +
                        getPrimaryContactClass(contact.is_primary)
                      "
                      v-tooltip.bottom="{
                        value: 'Make primary contact',
                        disabled: contact.is_primary === 1,
                      }"
                      @click="makeContactPrimary(contact)"
                    />
                  </span>
                  <div @click="
                      $store.dispatch('setSliderView', 'ContactSlider');
                      $store.dispatch('setSelectedObject', {
                        contact: contact,
                        obj: client,
                      });
                    ">
                    {{ contact.name }} {{ contact.surname }}
                  </div>
                </div>
                <div v-if="displayAccessories === contact.uid">
                  <i
                    v-tooltip.bottom="{
                      value: 'At least one contact should remain',
                      disabled: client.contacts.length > 1,
                    }"
                    :class="
                      'las la-trash i-20 ' +
                      (client.contacts.length === 1
                        ? 'disable-grey cursor-disable'
                        : 'grey')
                    "
                    @click.stop="
                      client.contacts.length == 1
                        ? () => {}
                        : deletePrompt(
                            contact.name +
                              ' ' +
                              (contact.surname ? contact.surname : ''),
                            '/v1/clients/delete/contact/' + contact.uid
                          )
                    "
                  />
                </div>
              </div>
              <Divider class="m-2"></Divider>
            </div>
          </template>
          <div>
            <Button
              label="New Contact"
              class="p-button-text p-button-secondary w-auto p-3"
              icon="las la-plus"
              @click="
                $store.dispatch('setSliderView', 'ContactSlider');
                $store.dispatch('setSelectedObject', {
                  obj: client,
                });
              "
            />
          </div>
        </div>
        <div
          v-else
          class="col-12 md:col-6"
        >
          <div class="p-fluid formgrid grid justify-content-start">
            <div class="field col-12">
              <h2>Contact</h2>
            </div>
            <div class="field col-12 sm:col-6">
              <label for="name">Title</label>
              <div class="p-inputgroup">
                <Dropdown
                  v-if="!isLoading"
                  :options="contactTitles"
                  optionLabel="title"
                  optionValue="uid"
                  placeholder="Select Title"
                  class="input"
                  v-model="contact.contact_title_uid"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label>Name</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeholder="Name"
                  v-model="contact.name"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.contact.name.required"
              >
                Name is required
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label>Surname</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeholder="Surname"
                  v-model="contact.surname"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.contact.surname.required"
              >
                Surname is required
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label for="name">Designation</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeholder="Designation"
                  v-model="contact.designation"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label for="name">Department</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeholder="Department"
                  v-model="contact.department"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <!-- <div
                class="error-validation-text pt-1"
                v-if="
                  submitted && !$v.contact.contactDetails.department.required
                "
              >
                Department is required
              </div> -->
            </div>
            <div class="field col-12 sm:col-6">
              <label for="name">Email</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeholder="Email"
                  v-model="contact.email"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="!$v.contact.email.email"
              >
                Please enter a valid email
              </div>
              <div
                class="error-validation-text pt-1"
                v-if="submitted && !$v.contact.email.required"
              >
                Email is required
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label for="name">Cellphone</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeholder="Cellphone number"
                  v-model="contact.cell_number"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label for="name">Telephone</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeholder="Cellphone number"
                  v-model="contact.tel_number"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label>Ext</label>
              <div class="p-inputgroup">
                <InputText
                  v-if="!isLoading"
                  placeholder="Extension"
                  v-model="contact.extension"
                />
                <Skeleton
                  height="100%"
                  borderRadius="6px"
                  v-else
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid mt-5">
        <div class="col-6 flex justify-content-between">
          <Button
            type="submit"
            label="SAVE"
          />

          <Button
            @click="
              $router.push('/clients');
              $store.dispatch('setSelectedObject', null);
            "
            label="CANCEL"
            class="p-button-text p-button-secondary"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import {
  state,
  fetchCurrency,
  fetchBillingTiers,
  fetchSettingsUsers,
  fetchClientCredits,
  fetchClientTerms,
  fetchClient,
  fetchContactTitles,
} from "../../../services/data_service.js";
export default {
  data() {
    return {
      isDirty: false,
      sameAddress: false,
      displayAccessories: false,
      isLoading: null,
      loadingText: "Loading...",
      submitted: false,
      newbillingTiers: [],
      currency: null,
      client: {
        company_registration: null,
        name: null,
        company_name: null,
        active: 1,
        display_name: null,
        client_code: null,
        telephone_number: null,
        email: null,
        website: null,
        physical_address: null,
        postal_address: null,
        tax_number: null,
        tax_rate: null,
        billing_tier_uid: null,
        account_executive_uid: null,
        client_credit: null,
        client_terms_uid: null,
        currency: null,
        contacts: [{}, {}, {}, {}, {}],
      },
      contact: {
        contact_title_uid: null,
        company_name: null,
        name: null,
        surname: null,
        is_primary: 1,
        designation: null,
        email: null,
        cell_number: null,
        tel_number: null,
        department: null,
        extension: null,
      },
    };
  },
  validations: {
    client: {
      company_name: {
        required,
      },
      display_name: {
        required,
      },
      client_code: {
        required,
      },

      email: {
        required,
        email,
      },

      tax_rate: {
        required,
      },
      billing_tier_uid: {
        required,
      },
      account_executive_uid: {
        required,
      },
    },
    contact: {
      name: {
        required,
      },
      surname: {
        required,
      },

      email: {
        required,
        email,
      },
    },
  },
  beforeMount() {
    state.client = null;
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  async mounted() {
    this.isLoading = true;
    await this.loadData();
    if (!this.$route.params.uid) {
      this.client.contacts = [];
    }
    this.isLoading = false;

    this.newbillingTiers = this.billingTiers.filter((tier) => {
      let currentDate = new Date();
      let billingTierDate = new Date(tier.apply_from);
      if (currentDate >= billingTierDate) {
        return tier;
      }
    });
  },
  methods: {
    async loadData() {
      var arr = [
        fetchCurrency(),
        fetchBillingTiers(),
        fetchSettingsUsers(),
        fetchClientCredits(),
        fetchClientTerms(),
        fetchContactTitles(),
      ];

      if (this.$route.params.uid) {
        arr.push(fetchClient(this.$route.params.uid));
      }
      await Promise.all(arr);
    },
    mouseenter(uid) {
      this.displayAccessories = uid;
    },
    setClient(client) {
      this.client.telephone_number = client.telephone_number;
      this.client.active = client.active;
      this.client.currency = client.currency.uid;
      this.client.account_executive_uid = client.account_executive_uid;
      this.client.company_name = client.company_name;
      this.client.display_name = client.display_name;
      this.client.email = client.email;
      this.client.tax_number = client.tax_number;
      this.client.tax_rate = Number(client.tax_rate);
      this.client.website = client.website;
      this.client.physical_address = client.physical_address;
      this.client.postal_address = client.postal_address;
      this.client.client_code = client.client_code;
      this.client.client_credit = parseFloat(client.client_credit);
      this.client.client_terms_uid = client.client_terms;
      this.client.billing_tier_uid = client.billing_tier_uid;
      this.client.contacts = [...client.contacts];
      this.currency = client.currency.name;
      this.client.company_registration = client.company_registration;
    },
    mouseleave() {
      this.displayAccessories = null;
    },
    makeContactPrimary(contact) {
      let contactInfo = { contact };
      if (contactInfo.contact.is_primary == 1) {
        return;
      } else {
        contactInfo.contact.is_primary = 1;
        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API +
              "/v1/clients/update/contact/" +
              contact.uid,
            JSON.stringify(contactInfo),
            {
              headers: { "Content-type": "application/json" },
            }
          )
          .then((response) => {
            this.isLoading = false;
            this.$toast.add({
              severity: "success",
              summary: `${contactInfo.contact.name} is now the primary contact`,
              life: 3000,
            });
            this.clearSliderContent();
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
    selectCurrency(uid) {
      this.billingTiers.map((billingTier) => {
        if (billingTier.uid == uid) {
          this.client.currency = billingTier.currency_uid;
          this.currencies?.map((currency) => {
            if (currency.uid == this.client.currency) {
              this.currency = currency.name;
            }
          });
        }
        return billingTier;
      });
    },
    syncAddress() {
      if (this.sameAddress === true) {
        this.client.postal_address = this.client?.physical_address;
      }
    },
    submitHandler() {
      this.submitted = true;
      this.client.name = this.client.company_name;
      if (!this.$route.params.uid) {
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$toast.add({
            severity: "error",
            summary: "Please complete all the required fields",
            life: 3000,
          });
        } else {
          if (!this.hasUid) {
            this.contact.company_name = this.client.company_name;
            this.client.contacts.push(this.contact);
          } else {
            this.contact = this.client.contacts[0];
          }
          this.isLoading = true;
          this.$axios
            .post(
              process.env.VUE_APP_ROOT_API +
                "/v1/clients/" +
                (!this.$route.params.uid ? "create" : "") +
                (!this.$route.params.uid ? "" : "/" + this.$route.params.uid),
              this.client,
              {
                headers: { "Content-type": "application/json" },
              }
            )
            .then((response) => {
              this.$router.push("/clients");
              this.isLoading = false;
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response && error.response.status === 401) {
                console.error("NOOOOOOOOO!");
              } else {
                // Handle error however you want
              }
              console.error(error);
            });
        }
      } else if (this.$route.params.uid) {
        this.$v.$touch();
        if (this.$v.client.$invalid == true) {
          this.$toast.add({
            severity: "error",
            summary: "Please complete all the required fields",
            life: 3000,
          });
        } else {
          this.isLoading = true;
          this.$axios
            .post(
              process.env.VUE_APP_ROOT_API +
                "/v1/clients/" +
                (!this.$route.params.uid ? "create" : "") +
                (!this.$route.params.uid ? "" : "" + this.$route.params.uid),
              this.client,
              {
                headers: { "Content-type": "application/json" },
              }
            )
            .then((response) => {
              this.$router.push("/clients");
              this.isLoading = false;
              this.$toast.add({
                severity: "success",
                summary: `Client ${this.client ? "updated" : "created"}`,
                life: 3000,
              });
            })
            .catch((error) => {
              this.isLoading = false;
              if (error.response && error.response.status === 401) {
                console.error("NOOOOOOOOO!");
              } else {
                // Handle error however you want
              }
              console.error(error);
            });
        }
      }
    },
    getPrimaryContactClass(t) {
      return t == 1 ? "is-primary-contact" : "not-primary-contact";
    },
  },
  computed: {
    currencySymbol() {
      var setCurrency = null;
      this.currencies?.map((currency) => {
        if (currency.uid == this.client.currency) {
          setCurrency = currency.description;
        }
      });
      return setCurrency;
    },
    billingTiers() {
      return state.billingTiers.filter(
        (billingTier) => billingTier.active == 1
      );
    },
    clientCredits() {
      return state.clientCredits;
    },
    clientTerms() {
      return state.clientTerms;
    },
    currencies() {
      return state.currencies;
    },
    users() {
      if (state.settingsUsers) {
        return state.settingsUsers.filter((user) => user.active == 1);
      }
      return null;
    },
    stateClient() {
      return state.client;
    },
    contactTitles() {
      return state.contactTitles;
    },
    hasUid() {
      return this.$route.params.uid;
    },
  },
  watch: {
    sameAddress(n) {
      if (n === true) {
        this.syncAddress();
      }
    },
    stateClient(n, o) {
      if (n) {
        this.setClient(n);
      }
    },
  },
  metaInfo() {
    return {
      title: this.hasUid
        ? this.stateClient
          ? this.stateClient.name
          : "Client"
        : "New Client",
      meta: [{ vmid: "description", name: "description", content: "" }],
    };
  },
};
</script>

<style lang="scss" scoped>
.is-primary-contact {
  background-color: white !important;
  border-color: white !important;
  color: #6dd230 !important;
}

.not-primary-contact {
  background-color: white !important;
  border-color: white !important;
  color: #778ca2 !important;
}

.not-primary-contact:hover {
  background-color: white !important;
  border-color: white !important;
  color: #6dd230 !important;
}
</style>
