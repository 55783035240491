<template>
  <div :style="'min-width: 300px; min-height: 100px; '">
    <div
      v-if="!hideSelectAll"
      class="field-checkbox absolute top-0 right-0 pr-4 mt-5"
      style="margin-right: 1%"
    >
      <label
        for="binary"
        class="mr-3"
      >Select All</label>
      <Checkbox
        id="binary"
        v-model="selectAll"
        :binary="true"
      />
    </div>
    <div
      v-else
      class="flex align-items-center justify-content-between"
    >
      <div class="ml-3 mb-3 text-18">Select Items</div>

      <div
        class="field-checkbox mb-0 mb-3"
        style="margin-right: 17px"
      >
        <label
          for="binary"
          class="mr-2"
        >Select All</label>
        <Checkbox
          id="binary"
          v-model="selectAll"
          :binary="true"
        />
      </div>
    </div>
    <div>
      <div
        v-for="group in groups"
        :key="group.id"
      >
        <div
          v-if="[...(group.tasks || []), ...(group.costs || [])]?.length > 0"
          style="padding-bottom: 26px"
        >
          <DataTable
            :value="[...(group.tasks || []), ...(group.costs || [])]"
            :rowClass="rowClass"
            responsiveLayout="scroll"
            class="dt-class hide-empty-dt"
            editMode="row"
            dataKey="uid"
          >
            <Column
              :styles="{
                width: '60%',
                verticalAlign: 'top !important',
              }"
              field="description"
              :footerStyle="{
                backgroundColor: 'white',
              }"
            >
              <template #header>
                <div class="font-light">
                  {{ group.title }}
                </div>
              </template>

              <template #body="slotProps">
                <div>
                  <div class="p-1">
                    <span v-if="slotProps.data.task_type_name">{{ slotProps.data.task_type_name }}
                    </span>
                    <span v-if="slotProps.data.type_name">{{ slotProps.data.type_name }}
                    </span>
                    <span v-if="
                        slotProps.data.title && slotProps.data.title !== 'null'
                      ">
                      - {{ slotProps.data.title }}
                    </span>
                  </div>

                  <div
                    v-if="slotProps.data && slotProps.data.description"
                    class="p-1 flex flex-wrap font-light"
                  >
                    <div v-html="markDown(slotProps.data.description)"></div>
                  </div>
                </div>
              </template>
            </Column>

            <Column
              field="option"
              :styles="{
                width: '1%',
                whiteSpace: 'nowrap',
                verticalAlign: 'top !important',
              }"
              :footerStyle="{
                backgroundColor: 'white',
              }"
            >
              <template #body="slotProps">
                <div
                  class="flex flex-column"
                  v-if="slotProps.data.item && slotProps.data.item.options"
                >
                  <div
                    class="col pr-0 ce-co"
                    v-for="(o, index) in slotProps.data.item.options"
                    :key="'q' + o.uid"
                  >
                    <span v-if="index > 0">option</span>
                    <span
                      v-else
                      style="width: 100%; display: inline-block"
                    >
                    </span>
                  </div>
                </div>
                <div
                  v-else-if="slotProps.data.item"
                  class="col pr-0"
                ></div>
              </template>
            </Column>

            <Column
              header="Qty"
              :styles="{
                width: '6%',
                verticalAlign: 'top !important',
                whiteSpace: 'nowrap',
              }"
              :footerStyle="{
                backgroundColor: 'white',
              }"
            >
              <template #body="slotProps">
                <div class="col pr-0">
                  {{
                    slotProps.data.qty
                      ? handleQty(slotProps.data.qty)
                      : slotProps.data.qty_full
                      ? slotProps.data.qty_full
                      : slotProps.data.estimated_hours
                      ? slotProps.data.estimated_hours
                      : slotProps.data.quantity
                      ? slotProps.data.quantity
                      : 0
                  }}
                </div>
              </template>
            </Column>
            <Column
              header="Price/Unit"
              field="rate"
              :styles="{
                width: '10%',
                verticalAlign: 'top !important',
                whiteSpace: 'nowrap',
              }"
              headerClass="header-right"
              :footerStyle="{
                backgroundColor: 'white',
              }"
            >
              <template #body="slotProps">
                <div class="col text-end pr-0">

                  {{
                    !!slotProps.data.rate
                      ? formatCurrency(
                          slotProps.data.rate,
                          job?.client?.currency?.description
                        ) + (slotProps.data.type === "cost" ? "" : "/hr")
                      : formatCurrency(
                          slotProps.data.price,
                          job?.client?.currency?.description
                        )
                  }}
                </div>
              </template>
            </Column>
            <!-- <Column
              v-if="!canSelect"
              headerClass="header-right"
              header="Total Amount"
              :footerStyle="{
                backgroundColor: 'white',
              }"
              :styles="{
                width: '10%',
                flexFlow: 'row-reverse',
                verticalAlign: 'top !important',
                textAlign: 'right',
                whiteSpace: 'nowrap',
              }"
            >
              <template #body="slotProps">
                <div class="flex flex-column" v-if="slotProps.data.total_price">
                  <div class="col text-end pr-0 ce-co">
                    {{
                      formatCurrency(
                        slotProps.data.total_price,
                        job?.client?.currency?.description
                      )
                    }}
                  </div>
                </div>
                <div v-else class="col text-end pr-3">-</div>
              </template>
            </Column> -->

            <Column
              headerClass="header-right"
              :header="!canSelect ? 'Billed Amount' : 'Total Billable'"
              field="active"
              :styles="{
                width: '10%',
                verticalAlign: 'top !important',
                textAlign: 'right',
                whiteSpace: 'nowrap',
              }"
              :footerStyle="{
                backgroundColor: 'white',
              }"
            >
              <template #body="slotProps">
                <div class="col text-end pr-0">
                  {{
                    slotProps.data.total
                      ? formatCurrency(
                          slotProps.data.total,
                          job?.client?.currency?.description
                        )
                      : slotProps.data.total_billable
                      ? formatCurrency(
                          slotProps.data.total_billable,
                          job?.client?.currency?.description
                        )
                      : slotProps.data.total_price
                      ? formatCurrency(
                          slotProps.data.total_price,
                          job?.client?.currency?.description
                        )
                      : slotProps.data.price || slotProps.data.price === 0
                      ? formatCurrency(
                          slotProps.data.price,
                          job?.client?.currency?.description
                        )
                      : formatCurrency(
                          (slotProps.data.seconds / 3600) *
                            (slotProps.data.type == "cost_type"
                              ? slotProps.data.resell_amount
                              : slotProps.data.rate),
                          job?.client?.currency?.description
                        )
                  }}
                </div>
              </template>
            </Column>
            <Column
              field="isSelect"
              v-if="canSelect"
              :styles="{
                width: '5% !important',
                verticalAlign: 'top !important',
              }"
            >
              <template #header>
                <Checkbox
                  name="id"
                  style="width: 25px"
                  :id="'g-cb' + group.id"
                  :value="group.id"
                  v-model="selectGroups"
                />
              </template>
              <template #body="slotProps">
                <Checkbox
                  name="id"
                  style="width: 25px"
                  :id="'cb' + slotProps.data.uid"
                  :value="slotProps.data.uid"
                  v-model="selected"
                />
              </template>
            </Column>
            <Column
              v-if="!canSelect"
              :styles="{ width: '5%', verticalAlign: 'top !important' }"
            >
              <template #body="slotProps">
                <div class="flex justify-content-end align-items-center invisible cursor-pointer">
                  <i
                    @click.stop="
                      deletePrompt(
                        slotProps.data.type_name,
                        '/v1/finance/invoice/remove/' + slotProps.data.uid,
                        'get'
                      )
                    "
                    class="las la-trash i-20 grey"
                  />
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>
    <div v-if="items === 0">No billable items</div>
    <div
      class="flex justify-content-between absolute pb-3 ml-1 pt-1 bg-white bottom-0 left-0 pl-3"
      v-if="canSelect"
      style="width: 99%"
    >
      <Button
        type="submit"
        :label="isEstimate ? 'Generate Estimate' : 'Add Items'"
        @click="add"
        :input-class="['is-primary']"
        :disabled="selected.length === 0 || isLoading"
        :loading="isLoading"
      />

      <div class="flex align-items-center">
        <div
          class="font-bold mr-2"
          v-if="items > 0"
        >
          Total:
          {{ formatCurrency(total, job?.client?.currency?.description) }}
        </div>
        <Button
          class="p-button-text p-button-secondary"
          @click="$emit('dismiss')"
          :loading="isLoading"
          :disabled="isLoading"
        >
          CANCEL
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { state } from "../../../services/data_service";

export default {
  props: {
    groups: {
      type: Array,
    },
    setCurrencySymbol: {
      type: String,
    },
    invoiceId: {
      type: String,
    },
    jobId: {
      type: String,
    },
    canSelect: {
      type: Boolean,
      default: true,
    },
    notInvoiced: {
      type: Boolean,
      default: false,
    },
    selectAllDefault: {
      type: Boolean,
      default: false,
    },
    isEstimate: {
      type: Boolean,
      default: false,
    },
    hideSelectAll: {
      type: Boolean,
      default: false,
    },
    ceName: {
      type: String,
    },
    ceDescription: {
      type: String,
    },
    validUntil: {
      type: String,
    },
    terms: {
      type: String,
    },
  },
  data() {
    return {
      selected: [],
      isLoading: false,
      selectAll: false,
      selectGroups: [],
    };
  },
  validations: {
    ceName: {
      required,
    },
    ceDescription: {
      required,
    },
  },
  beforeMount() {},
  mounted() {
    if (this.selectAllDefault) {
      this.selectAll = true;
      this.groups?.forEach((g) => {
        this.selectGroups.push(g.id);
      });
    }
  },
  methods: {
    handleQty(qty) {
      // check if qty is a number
      if (isNaN(qty)) {
        return qty;
      }
      return parseFloat(qty).toFixed(2);
    },
    markDown(text) {
      return text
        .replace(/<\/p><p>/g, "<br>") // Replace all </p><p> with <br>
        .replace(/<p>/g, "") // Remove all <p> tags
        .replace(/<\/p>/g, "") // Remove all </p> tags
        .replace(/\n/g, "<br>"); // Replace all newlines with <br>
    },
    rowClass() {
      return "";
    },
    getMargin(obj) {
      if (obj.type !== "task_type") {
        return (
          parseFloat(obj.price) * obj.quantity -
          parseFloat(obj.unit_price) * obj.quantity
        );
      }
      return "-";
    },
    add() {
      if (this.isEstimate) {
        this.$v.ceName.$touch();
        this.$v.ceDescription.$touch();
        if (this.$v.$invalid) {
          this.$toast.add({
            severity: "error",
            summary: "Please fill in all required fields",
            life: 3000,
          });
          return;
        }
      }

      this.isLoading = true;
      var route = this.isEstimate
        ? "/v1/finance/estimate"
        : "/v1/finance/invoice/add";

      // var tasks_uids is all selected that start with t
      // var tasks_uids = this.selected.filter((s) => s.startsWith("t"));
      // var costs_uids is all selected that start with c
      // var costs_uids = this.selected.filter((s) => s.startsWith("c"));

      var body = this.isEstimate
        ? {
            job: this.jobId,
            task_uid: this.selected.join(","),
            name: this.ceName,
            description: this.ceDescription,
            date_due: this.validUntil,
            terms: this.terms,
          }
        : {
            invoice: this.invoiceId,
            task_uid: this.selected.join(","),
          };

      this.$axios
        .post(process.env.VUE_APP_ROOT_API + route, body, {
          headers: { "Content-type": "application/json" },
        })
        .then((response) => {
          this.isLoading = false;
          this.$emit("dismiss");
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        });
    },
  },
  computed: {
    job() {
      return state.job;
    },
    total() {
      var total = 0;
      this.selected?.forEach((s) => {
        this.groups?.forEach((g) => {
          g.tasks?.forEach((t) => {
            if (t.uid === s) {
              total += t.price;
            }
          });
          g.costs?.forEach((c) => {
            if (c.uid === s) {
              total += c.total_price;
            }
          });
        });
      });
      return total;
    },
    items() {
      var total = 0;

      this.groups?.forEach((group) => {
        var s = [...(group.tasks || []), ...(group.costs || [])];
        total += s.length;
      });
      return total;
    },
  },
  watch: {
    selectAll(val) {
      if (val == true) {
        this.groups?.forEach((g) => {
          this.selectGroups.push(g.id);
        });

        this.groups?.forEach((g) => {
          g?.tasks?.forEach((t) => {
            if (this.selected.indexOf(t.uid) === -1) {
              this.selected.push(t.uid);
            }
          });
          g?.costs?.forEach((c) => {
            if (this.selected.indexOf(c.uid) === -1) {
              this.selected.push(c.uid);
            }
          });
        });
      } else {
        this.selected = [];
        this.selectGroups = [];
      }
    },
    selectGroups(newGroups, oldGroups) {
      // Check if items were added
      if (newGroups.length > oldGroups.length) {
        var addedGroupId = newGroups.find((id) => !oldGroups.includes(id));
        var addedGroup = this.groups.find((g) => g.id === addedGroupId);
        addedGroup?.tasks?.forEach((t) => {
          if (this.selected.indexOf(t.uid) === -1) {
            this.selected.push(t.uid);
          }
        });
        addedGroup?.costs?.forEach((c) => {
          if (this.selected.indexOf(c.uid) === -1) {
            this.selected.push(c.uid);
          }
        });
      } else {
        // Items were removed
        var removedGroupId = oldGroups.find((id) => !newGroups.includes(id));
        var removedGroup = this.groups.find((g) => g.id === removedGroupId);
        removedGroup?.tasks?.forEach((t) => {
          this.selected = this.selected.filter((s) => s !== t.uid);
        });
        removedGroup?.costs?.forEach((c) => {
          this.selected = this.selected.filter((s) => s !== c.uid);
        });
      }
    },
  },
};
</script>

<style>
</style>