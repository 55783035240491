<template>

  <div>
    <DataTable
      responsiveLayout="scroll"
      :rowClass="rowClass"
      :value="templates"
      editMode="row"
      :filters.sync="filters"
      filterDisplay="menu"
      dataKey="uid"
      @row-click="navJob"
      :paginator="true"
      :rows="30"
      :rowsPerPageOptions="[30, 50, 100]"
    >
      <template #empty>
        <div>No Templates Yet</div>
      </template>
      <Column
        field="title"
        sortable
        header="Jobs"
      >
        <template #body="slotProps">
          <Skeleton
            class="mt-1"
            width="75%"
            v-if="isLoading"
          ></Skeleton>
          <div
            class="cursor-pointer"
            v-else
          >
            {{ slotProps.data[slotProps.column.field] }}
          </div>
        </template>
        <template #editor="slotProps">
          <InputText
            placeHolder="Name"
            v-model="slotProps.data.type_name"
          />
        </template>
      </Column>
      <Column
        field="category"
        header="Category"
        sortable
      >
        <template #filter="{ filterModel }">
          <InputText
            type="text"
            v-model="filterModel.value"
            class="p-column-filter"
            placeholder="Search by category"
          />
        </template>
        <template #body="slotProps">
          <Skeleton v-if="isLoading"></Skeleton>
          <div v-else>{{ slotProps.data[slotProps.column.field] }}</div>
        </template>
      </Column>
      <Column
        bodyStyle="text-align:right"
        :styles="{ width: '1rem' }"
      >
        <template #body="slotProps">
          <div class="flex justify-content-end align-items-center invisible cursor-pointer">
            <i
              @click.stop="navJobFromTemplateMode(slotProps.data)"
              class="las la-plus-circle i-20 grey pr-2"
            />

            <i
              @click.stop="
                  deletePrompt(
                    slotProps.data.title,
                    '/v1/jobs/' + slotProps.data.uid
                  )
                "
              class="las la-trash i-20 grey"
            />
          </div>
        </template>
      </Column>
    </DataTable>
  </div>

</template>

<script>
import { state, fetchTemplates } from "../../../services/data_service.js";
import { FilterMatchMode } from "primevue/api";
export default {
  data() {
    return {
      isLoading: true,
      drag: false,
      originalRows: [],
      editingRows: [],
      editingRow: null,
      tempaltes: [],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        category: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
  },
  async mounted() {
    if (!this.templates) {
      state.templates = [{}, {}, {}, {}, {}, {}];
    }
    await this.loadData();
    this.isLoading = false;
  },
  computed: {
    templates() {
      return state.templates;
    },
  },
  methods: {
    async loadData() {
      await fetchTemplates();
    },
    navJob(ev) {
      this.$store.dispatch("setSelectedObject", ev.data);
      this.$router.push("/job/" + ev.data.uid);
    },
    rowClass() {
      return "row-accessories row-accessories2";
    },
    navJobFromTemplateMode(ev) {
      ev.job_from_template = 1;
      this.$store.dispatch("setSelectedObject", ev);
      this.$store.dispatch("setSliderView", "JobSlider");
    },
    navEditMode(ev) {
      this.$store.dispatch("setSelectedObject", ev);
      this.$store.dispatch("setSliderView", "JobSlider");
    },
  },
  metaInfo: {
    title: "Settings | Job Templates",

    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "" },
      { name: "keywords", content: "" },
    ],
  },
};
</script>
