<template>
  <div
    class="container-padding"
    align="left"
  >
    <TaskScheduleHeader
      :overrun="overrun"
      :overdue="overdue"
      :unplanned="unplanned"
      @switchView="switchView"
      :isLoading="isLoading"
      @filter="filter"
      :isTimesheetView="isTimesheetView"
      :filters="filters"
      :isRefreshing="isRefreshing"
      @refresh="
        isRefreshing = true;
        getData(true, 0, false);
      "
    />

    <Menu
      ref="menu"
      :popup="true"
      :model="items"
    />
    <ContextMenu
      ref="menuCell"
      :popup="true"
      :model="items2"
    />
    <OverlayPanel
      ref="op"
      style="width: 250px"
    ><span class="p-input-icon-right mb-3">
        <InputText
          @input="handleTaskSchedule"
          v-model="taskScheduleSearchTeam"
          style="width: 224.5px"
          placeholder="Search..."
        />
        <i
          class="las la-times cursor-pointer"
          @click="
            taskScheduleSearchTeam = '';
            handleTaskSchedule('');
          "
        ></i>
      </span>
      <Dropdown
        v-model="selectedFilter"
        placeholder="Filter"
        style="width: 224.5px"
        optionLabel="name"
        :options="projectManagers"
        :showClear="true"
      >
        <template #value="slotProps">
          <div
            class="flex align-items-center"
            v-if="slotProps.value"
          >
            <Avatar
              :image="slotProps.value.profile_picture"
              :label="
                !slotProps.value.profile_picture
                  ? getIntials(slotProps.value)
                  : null
              "
              :style="{
                'background-color': !slotProps.value.profile_picture
                  ? getColorFromName(slotProps.value.profile_picture)
                  : 'none',
                color: '#ffffff',
              }"
              shape="circle"
              class="mr-3"
            />
            <div>{{ slotProps.value.name }}</div>
          </div>
          <span v-else>
            {{ slotProps.placeholder }}
          </span>
        </template>
        <template #option="slotProps">
          <div class="flex align-items-center">
            <Avatar
              :image="slotProps.option.profile_picture"
              :label="
                !slotProps.option.profile_picture
                  ? getIntials(slotProps.option)
                  : null
              "
              :style="{
                'background-color': !slotProps.option.profile_picture
                  ? getColorFromName(slotProps.option.profile_picture)
                  : 'none',
                color: '#ffffff',
              }"
              size="large"
              shape="circle"
              class="mr-3"
            />
            <div>
              {{ slotProps.option.name }}<br /><span class="font-light text-12">{{ slotProps.option.type }}</span>
            </div>
          </div>
        </template>
      </Dropdown>
    </OverlayPanel>

    <div v-if="today !== null && !isLoading">
      <div>
        <div class="tasks-schedule card">
          <simplebar
            ref="tableScroll"
            class="table-scroll"
          >
            <table
              id="main-table"
              @mouseup="mouseUp()"
              @mouseleave="mouseUp()"
              @contextmenu="handleRightClick"
            >
              <thead
                class="resource-thead"
                style="border-top: 1px solid rgb(232, 236, 239)"
              >
                <th
                  class="col header department-header resource-th"
                  style="
                    border-right: 4px solid rgb(232, 236, 239);
                    min-width: 300px;
                  "
                />

                <th
                  :colspan="monthHeaders[index] ? '3' : '1'"
                  rowspan="2"
                  :style="monthHeaders[index] ? 'z-index:3;font-size:12px' : ''"
                  class="col pb-0 pt-0 font-light month-header"
                  v-for="(i, index) in days"
                  :key="index"
                >
                  <p
                    style="color: #98a9bc"
                    class="font-light"
                  >
                    <span v-if="monthHeaders[index]">
                      {{ monthHeaders[index].m }}
                      {{ monthHeaders[index].y }}</span>
                  </p>
                </th>
              </thead>
              <thead class="resource-thead-days">
                <th
                  ref="departmentHeader"
                  class="header department-header resource-th"
                  style="border-right: 4px solid rgb(232, 236, 239); z-index: 9"
                >
                  <div class="flex justify-content-center align-items-center">
                    <Button
                      :icon="
                        isFilteringActive
                          ? 'las la-filter active-filter-icon'
                          : 'las la-filter'
                      "
                      class="mr-3 ml-4 p-button-outlined"
                      :class="
                        isFilteringActive
                          ? 'p-button-primary'
                          : 'p-button-secondary'
                      "
                      :style="
                        'width: 100%;' +
                        (isFilteringActive
                          ? 'border-color:#4D7CFE'
                          : 'border-color: #e8ecef')
                      "
                      type="button"
                      label="Filter"
                      @click="toggleOverlay"
                    />
                    <Button
                      @click="expandCollapse(true)"
                      icon="las la-plus"
                      class="p-button-text p-button-secondary p-button-sm p-button-rounded mr-1"
                    />

                    <Button
                      @click="expandCollapse(false)"
                      icon="las la-minus"
                      class="p-button-text p-button-secondary p-button-sm p-button-rounded"
                    />
                  </div>
                </th>

                <!-- <td style="border-top: 3px solid rgb(232, 236, 239)"></td> -->
                <th
                  class="col text-center p-2 date-cell department-header"
                  :style="
                    index === today
                      ? 'border-left-style: solid;  border-left-color: #4D7CFE;border-left-width:0.15rem'
                      : ''
                  "
                  v-for="(i, index) in days"
                  :key="index"
                >
                  <span
                    v-if="index === firstDate && firstDate > minDate"
                    class="load-prev-icon"
                  >
                    <i
                      v-if="!prevLoading"
                      class="pi pi-chevron-left cursor-pointer"
                      @click="
                        prevLoading = true;
                        getData(false, isTimesheetView == 1 ? -7 : getPrevDays() , true);
                      "
                    />
                    <ProgressSpinner
                      v-if="prevLoading"
                      animationDuration=".75s"
                      style="width: 16px; height: 16px"
                      strokeWidth="4"
                    />
                  </span>

                  <span
                    v-if="index === lastDate"
                    class="load-next-icon"
                  >
                    <i
                      v-if="!prevLoading"
                      class="pi pi-chevron-right cursor-pointer"
                      @click="
                        prevLoading = true;
                        getData(false, isTimesheetView == 1 ? 7 : 14, true);
                      "
                    />
                    <ProgressSpinner
                      v-if="prevLoading"
                      animationDuration=".75s"
                      style="width: 16px; height: 16px"
                      strokeWidth="4"
                    />
                  </span>
                  <p
                    style="color: #778ca2; font-size: x-small"
                    class="font-light"
                  >
                    {{ i.day }}
                  </p>
                  <p
                    class="font-light"
                    style="color: #778ca2; font-size: small"
                  >
                    {{ i.date }}
                  </p>
                </th>
              </thead>
              <thead
                v-if="!overFilter(users)"
                style="padding: 80px; font-size: 16px"
                align="center"
              >
                <td style="
                    display: inline-block;
                    padding: 30px 80px;
                    background: #f8fafb;
                    border-radius: 100px;
                    position: absolute;
                    top: 30%;
                    left: 30%;
                  ">
                  Nothing to display
                </td>
              </thead>
              <template v-for="user in usersDisplayCopy">
                <draggable
                  :animation="140"
                  :key="user.uid"
                  tag="tbody"
                  handle=".handle"
                  v-if="notFiltered(user.tasks)"
                  draggable=".dragitem"
                  @end="handleDragEnd(user)"
                  :list="user.tasks"
                >
                  <TaskScheduleTaskRow
                    class="item"
                    v-for="(task, index) in user.tasks"
                    :isTimesheetView="isTimesheetView == 1"
                    :index="index"
                    :key="task.uid"
                    :user="user"
                    :isFiltered="isFiltered(task)"
                    :days="days"
                    :showUnplanned="showUnplannedUsers.includes(user.uid)"
                    @toggle="toggle"
                    :isTop="index == 0"
                    :isBottom="index == user.tasks.length - 1"
                    :selectedCells="selectedCells"
                    :task="task"
                    @toggleCellMenu="toggleCellMenu"
                    :today="today"
                    :yesterday="yesterday"
                    @isDragging="isDragging = true"
                    :isDragging="isDragging"
                    @lastToggle="updateLastToggle"
                    @showConfirmDialog="showConfirmDialog = true"
                  />

                  <tr
                    slot="header"
                    class="resource-thead-user"
                    id="userRow"
                  >
                    <TaskScheduleUserHeader
                      :user="user"
                      @expandToggle="expandToggle(user)"
                      @sortUser="sortUserTasks(user)"
                    />

                    <TaskScheduleProgress
                      v-for="(i, index) in days"
                      :key="index"
                      :index="index"
                      :user="users.find((a) => a.uid == user.uid)"
                      :today="today"
                      :isTimesheetView="isTimesheetView == 1"
                      :dayAbbr="i.dayAbbr"
                    />
                  </tr>

                  <tr
                    slot="header"
                    v-if="isTimesheetView == 1"
                  >
                    <TaskScheduleUserTimesheetHeader
                      :userTasks="user.tasks"
                      :workingHours="user.working_hours"
                    />
                    <TaskScheduleWeeklyWorkload
                      v-for="week in weeks"
                      :key="week[0]"
                      :week="week"
                      :today="today"
                      :userTasks="user.tasks"
                      :workingHours="user.working_hours"
                    ></TaskScheduleWeeklyWorkload>
                  </tr>

                  <TaskScheduleUnplannedRow
                    slot="header"
                    v-else
                    :user="user"
                    :days="days"
                    :today="today"
                    @taskToggle="handleTaskToggle(user)"
                    :showUnplannedUsers="showUnplannedUsers"
                  ></TaskScheduleUnplannedRow>
                </draggable>
              </template>
            </table>
          </simplebar>
        </div>
      </div>
    </div>

    <TaskScheduleLoading v-else />

    <Dialog :visible.sync="showEditDialog">
      <label
        for="Time"
        class="mb-5"
      > Schedule Time</label>
      <TimePicker
        :time="editBlockTime"
        @update="editBlockTime = $event"
      />

      <template #footer>
        <div class="flex justify-content-between">
          <Button
            type="submit"
            label="SAVE"
            :disabled="!editBlockTime"
            class="is-primary"
            @click="saveEditBlockTime"
          />
          <Button
            class="p-button-text p-button-secondary"
            @click="showEditDialog = false"
          >
            CANCEL
          </Button>
        </div>
      </template>
    </Dialog>

    <Dialog :visible.sync="showConfirmDialog">
      This task is overrun and does not have time left to schedule. <br />
      Schedule anyway?
      <template #footer>
        <div class="flex justify-content-between">
          <Button
            type="submit"
            label="Schedule"
            class="is-primary"
            @click="
              editAndRecalculate(true);
              isOverrun = true;
              showConfirmDialog = false;
            "
          />
          <Button
            class="p-button-text p-button-secondary"
            @click="showConfirmDialog = false"
          >
            CANCEL
          </Button>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import TimePicker from "../../Tasks/components/TimePicker.vue";
import draggable from "vuedraggable";
import { fetchUserTasks, state } from "../../../services/data_service.js";
import Pusher from "pusher-js";
import TaskScheduleUserTimesheetHeader from "../components/TaskScheduleUserTimesheetHeader.vue";
import TaskScheduleUserHeader from "../components/TaskScheduleUserHeader.vue";
import TaskScheduleHeader from "../components/TaskScheduleHeader.vue";
import TaskScheduleProgress from "../components/TaskScheduleProgress.vue";
import TaskScheduleTaskRow from "../components/TaskScheduleTaskRow.vue";
import TaskScheduleLoading from "./TaskScheduleLoading.vue";
import TaskScheduleWeeklyWorkload from "../components/TaskScheduleWeeklyWorkload.vue";
import TaskScheduleUnplannedRow from "../components/TaskScheduleUnplannedRow.vue";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
export default {
  data() {
    return {
      isLoading: null,
      showEditDialog: false,
      showConfirmDialog: false,
      editBlockTime: null,
      isSending: false,
      prevLoading: false,
      previousDays: 0,
      monthHeaders: [],
      today: null,
      users: [],
      maxDate: null,
      minDate: null,
      selectedCells: [],
      lastToggle: 0,
      isDragging: false,
      firstDate: null,
      lastDate: null,
      userTaskUid: null,
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      dayNames: ["S", "M", "T", "W", "T", "F", "S"],
      dayAbbreviations: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
      selectedFilter: null,
      taskScheduleSearchTeam: null,
      days: null,
      weeks: null,
      firstMonday: null,
      usersDisplayCopy: [],
      items: [
        {
          label: "Unlock all and recalculate",
        },
        {
          label: "Distribute all existing",
        },
        {
          label: "Lock all time",
        },
        {
          label: "Unlock all time",
        },
        {
          label: "Optimise",
        },
      ],
      items2: [],
      settings: {},
      filters: [],
      isTimesheetView: 0,
      showUnplannedUsers: [],
      isRefreshing: false,
      windowWidth: window.innerWidth,
      resizeTimeout: null, // Used to debounce resize
    };
  },
  components: {
    TaskScheduleUserHeader,
    TaskScheduleHeader,
    TaskScheduleProgress,
    TaskScheduleTaskRow,
    TaskScheduleWeeklyWorkload,
    draggable,
    simplebar,
    TaskScheduleLoading,
    TaskScheduleUserTimesheetHeader,
    TaskScheduleUnplannedRow,
    TimePicker,
  },

  computed: {
    isFilteringActive() {
      return this.taskScheduleSearchTeam || this.selectedFilter;
    },
    task_types() {
      return state.taskTypes
        ? state.taskTypes.filter((task) => task.active == 1)
        : null;
    },
    account_uid() {
      return this.user?.account_uid;
    },
    user() {
      return this.$store.getters.user;
    },
    selectedObject() {
      return this.$store.getters.selectedObject;
    },
    filteredTasks() {
      return this.users.map((user) => {
        return {
          ...user,
          tasks: user.tasks.filter((task) => !this.isFiltered(task)),
        };
      });
    },

    overdue() {
      return this.filteredTasks.reduce((count, user) => {
        return (
          count +
          user.tasks.filter(
            (task) => task.date_due && task.date_due.time_until === "Overdue"
          ).length
        );
      }, 0);
    },

    overrun() {
      return this.filteredTasks.reduce((count, user) => {
        return (
          count +
          user.tasks.filter(
            (task) =>
              parseFloat(task.minutes || 0) >
              parseFloat(task.estimated_minutes || 0)
          ).length
        );
      }, 0);
    },

    unplanned() {
      return this.filteredTasks.reduce((count, user) => {
        return (
          count +
          user.tasks.filter(
            (task) =>
              task.hours_unplanned &&
              task.hours_planned !== task.estimated_hours &&
              task.minutes_unplanned > 0
          ).length
        );
      }, 0);
    },

    projectManagers() {
      const projectManagers = new Set();
      this.filteredTasks.forEach((user) => {
        if (user.tasks) {
          user.tasks.forEach((task) => {
            projectManagers.add(task.project_manager);
          });
        }
      });
      return Array.from(projectManagers)
        .map((p) => {
          const u = this.userTasks.find((a) => a.uid === p);
          if (u) {
            u.type = "Project Manager";
            return u;
          }
          return null;
        })
        .filter(Boolean);
    },
    userTasks() {
      return state.userTasks;
    },
    task() {
      return state.task;
    },
  },

  methods: {
    getPrevDays() {
      // if firstDate is before today return -4

      if (new Date(this.firstDate) < new Date()) {
        return -4;
      }

      // // if firstdate is after today but firstDate - 14 days is more that 4 days ago return the difference so that the firstDate is 4 days ago
      // if (new Date(this.firstDate).addDays(-14) > new Date().addDays(-4)) {
      //   console.log(
      //     "firstDate is after today but firstDate - 14 days is more that 4 days ago"
      //   );
      //   return new Date(this.firstDate)
      //     .addDays(-14)
      //     .diffDays(new Date().addDays(-4));
      // }

      return -14;
    },
    handleTaskToggle(user) {
      this.showUnplannedUsers.includes(user.uid)
        ? this.showUnplannedUsers.splice(
            this.showUnplannedUsers.indexOf(user.uid),
            1
          )
        : this.showUnplannedUsers.push(user.uid);
    },
    switchView(isTimesheetView) {
      this.previousDays = 0;
      this.isTimesheetView = isTimesheetView;
      if (this.isTimesheetView == 1) {
        this.getData(true, -21, false);
      } else {
        this.getData(true, -4, false);
      }
    },
    toggleOverlay(event) {
      this.$refs.op.toggle(event);
    },
    handleTaskSchedule(text) {
      if (!text) {
        this.usersDisplayCopy = this.users;
        return;
      }

      const searchTerm = text.toLowerCase();

      this.usersDisplayCopy = this.users
        .map((user) => {
          // Check if user matches the search term
          const matchesUser =
            user.name?.toLowerCase().includes(searchTerm) ||
            user.surname?.toLowerCase().includes(searchTerm) ||
            user.username?.toLowerCase().includes(searchTerm);

          // Filter tasks based on the search term
          const filteredTasks =
            user.tasks?.filter(
              (task) =>
                task.title?.toLowerCase().includes(searchTerm) ||
                task.job?.client?.toLowerCase().includes(searchTerm) ||
                task.task_type_name?.toLowerCase().includes(searchTerm)
            ) || [];

          // If user matches or has any tasks matching the search term, include them
          if (matchesUser || filteredTasks.length > 0) {
            return { ...user, tasks: matchesUser ? user.tasks : filteredTasks };
          }

          return null;
        })
        .filter(Boolean);
    },

    extendDueDate(task) {
      var form = new FormData();
      form.append("title", task.title);
      form.append("rate", task.rate);
      form.append("billable", task.billable);
      form.append("description", task.description);
      form.append("date_start", task.date_start?.date);
      form.append("date_due", this.selectedObject.date);
      form.append("is_approved", task.is_approved);
      form.append("estimated_hours", task.estimated_hours);
      form.append("extend_date", 1);
      form.append("task_type", task.task_type_uid);
      this.$axios.post(
        process.env.VUE_APP_ROOT_API +
          "/v1/tasks/" +
          this.selectedObject.task_uid,
        form,
        {
          headers: { "Content-type": "application/x-www-form-urlencoded" },
        }
      );
    },
    lockTime() {
      this.selectedCells.push({
        uid: this.selectedObject.user_uid,
        tid: this.selectedObject.task_uid,
        date: [this.selectedObject.date],
        locking: true,
      });
      this.handleRequestQueue();
    },
    editAndRecalculate(isOverrun = false) {
      var userTask = this.users
        .find((a) => a.uid === this.selectedObject.user_uid)
        ?.tasks.find((b) => b.uid === this.selectedObject.task_uid);

      if (!userTask) {
        return;
      }

      if (
        isOverrun === false &&
        parseFloat(userTask.minutes) > parseFloat(userTask.estimated_minutes)
      ) {
        this.showConfirmDialog = true;
        return;
      }

      var minutes = userTask.resource_plan.find(
        (a) => a.date === this.selectedObject.date
      )?.minutes;

      if (!minutes) {
        this.editBlockTime = this.secToHM(0);
      } else {
        var seconds = minutes * 60;
        this.editBlockTime = this.secToHM(seconds);
      }
      this.showConfirmDialog = false;
      this.showEditDialog = true;
    },
    saveEditBlockTime() {
      this.selectedCells.push({
        uid: this.selectedObject.user_uid,
        tid: this.selectedObject.task_uid,
        date: [this.selectedObject.date],
        time: this.editBlockTime,
      });
      this.handleRequestQueue();
      this.editBlockTime = null;
      this.showEditDialog = false;
    },
    expandCollapse(v) {
      if (!v) {
        var arr = [];
        this.users.forEach((a) => {
          arr.push(a.uid);
        });
        this.settings.userCollapse = arr;
      } else {
        this.settings.userCollapse = [];
      }
    },
    filter(f) {
      var i = this.filters.indexOf(f);
      if (i >= 0) {
        this.filters.splice(i, 1);
      } else {
        this.filters.push(f);
      }
    },
    notFiltered(tasks) {
      var c = 0;
      tasks.forEach((task) => {
        if (!this.isFiltered(task) && c === 0) {
          c++;
        }
      });
      return c > 0;
    },
    overFilter(users) {
      var c = 0;
      users.forEach((user) => {
        user.tasks.forEach((task) => {
          if (!this.isFiltered(task)) {
            c++;
          }
        });
      });
      return c > 0;
    },
    isFiltered(task) {
      // Check if the task should be filtered out based on overrun
      if (this.filters.includes("overrun")) {
        if (
          parseFloat(task.minutes || 0) <=
          parseFloat(task.estimated_minutes || 0)
        ) {
          return true; // Task should be filtered out by overrun
        }
      }

      // Check if the task should be filtered out based on overdue
      if (this.filters.includes("overdue")) {
        if (task.date_due.time_until !== "Overdue") {
          return true; // Task should be filtered out by overdue
        }
      }

      // Check if the task should be filtered out based on unplanned work
      if (this.filters.includes("unplanned")) {
        if (
          !task.hours_unplanned ||
          task.hours_planned === task.estimated_hours ||
          task.minutes_unplanned <= 0
        ) {
          return true; // Task should be filtered out by unplanned
        }
      }

      // Filter by selected project manager
      if (this.selectedFilter) {
        if (task.project_manager !== this.selectedFilter.uid) {
          return true; // Task should be filtered out by project manager filter
        }
      }

      return false; // Task passes all filters and should not be filtered out
    },

    expandToggle(u) {
      if (this.settings.userCollapse.includes(u.uid)) {
        this.settings.userCollapse = this.arrayRemove(
          this.settings.userCollapse,
          u.uid
        );
      } else {
        this.settings.userCollapse.push(u.uid);
      }
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    toggleCellMenu(event) {
      const { user_uid, task_uid, date, locked } = this.selectedObject;

      const user = this.users.find((user) => user.uid === user_uid);
      const task = user?.tasks.find((task) => task.uid === task_uid);

      if (!task) return; // Early exit if task not found

      const hasTime = task.resource_plan.some((plan) => plan.date === date);
      const isDateOverdue = new Date(date) > new Date(task?.date_due?.date);
      const isFutureDate = new Date(date) > new Date(); // Check if the block is in the future

      this.items2 = this.getMenuOptions(
        locked,
        hasTime,
        isDateOverdue,
        isFutureDate
      );

      this.$refs.menuCell.show(event);
    },

    getMenuOptions(locked, hasTime, isDateOverdue, isFutureDate) {
      if (isDateOverdue) {
        return this.getDueDateMenu();
      }

      const menu = [
        {
          label: locked ? "Unlock time block" : "Lock time block",
          command: () => this.lockTime(),
        },
        {
          label: hasTime ? "Edit and recalculate" : "Add and recalculate",
          command: () => this.editAndRecalculate(),
        },
      ];

      // Add "Schedule Leave Day" option for future dates without time assigned
      if (isFutureDate && !hasTime) {
        menu.push({
          label: "Schedule Leave Day",
          command: () => this.scheduleLeaveDay(),
        });
      }

      return menu;
    },

    getDueDateMenu() {
      return [
        {
          label: "Extend Due Date",
          command: () => this.extendDueDate(),
        },
      ];
    },

    scheduleLeaveDay() {
      // Logic to schedule a leave day
      console.log("Scheduling a leave day...");
      // Implement the logic to mark this block as a leave day
    },
    updateLastToggle(e) {
      this.lastToggle = e;
    },
    subscribeChannel() {
      var channel = this.$pusher.subscribe(this.account_uid);
      channel.bind("task", (data) => {
        this.getData(false, 0, true);
      });
    },
    handleDragEnd(user) {
      const unplanned = [];
      const planned = [];

      user.tasks.forEach((t) => {
        if (t.hours_planned > 0 || t.minutes_planned > 0) {
          planned.push(t);
        } else {
          unplanned.push(t);
        }
      });

      user.tasks = [...unplanned, ...planned];

      var uidArr = [];
      user.tasks.forEach((t) => {
        uidArr.push(t.uid);
      });
      var uidString = uidArr.join(",");
      var form = new FormData();
      form.append("tasks", uidString);
      this.$axios
        .post(
          process.env.VUE_APP_ROOT_API +
            "/v1/users/" +
            user.uid +
            "/tasks/order",
          form,
          {
            headers: { "Content-type": "application/x-www-form-urlencoded" },
          }
        )
        .then((response) => {});
    },
    getDates(startDate, stopDate) {
      var dateArray = {};
      var currentDate = startDate;
      var today = new Date();

      this.yesterday = this.buildShortDate(today.addDays(-1));
      this.today = this.buildShortDate(today);

      while (currentDate <= stopDate.addDays(1)) {
        // new date from currentDate but at midnight
        var d = new Date(currentDate);

        var shortDate = this.buildShortDate(d);
        var newDate = {
          date: d.getDate(),
          month: d.getMonth(),
          year: d.getFullYear(),
          day: this.dayNames[d.getDay()],
          dayAbbr: this.dayAbbreviations[d.getDay()],
        };
        dateArray[shortDate] = newDate;

        currentDate = currentDate.addDays(1);
      }

      return dateArray;
    },
    async getData(mustLoad = true, previousDays = -4, keepOrder = true) {
      // log start with timestamp
      this.isLoading = mustLoad;

      // var taskScheduleCard = document.getElementsByClassName("layout-main")[0];
      // get taskScheduleCard width
      // var taskScheduleCardWidth = taskScheduleCard.offsetWidth;
      // subtract 3rem from taskScheduleCardWidth
      // taskScheduleCardWidth -= 48;
      // subtract 300px from taskScheduleCardWidth
      // taskScheduleCardWidth -= 300;
      // divide vy 47 to get number of days
      // var days = Math.floor(taskScheduleCardWidth / 48);
      var date_from = new Date().addDays(this.previousDays + previousDays);
      // date_to is date_from plus days
      var date_to = new Date(date_from).addDays(28);
      // convert to dd-mm-yyyy
      date_from = date_from.toISOString().split("T")[0];
      // convert to dd-mm-yyyy
      date_to = date_to.toISOString().split("T")[0];

      await fetchUserTasks(keepOrder, this.isTimesheetView, date_from, date_to);

      // add all users to showUnplannedUsers
      if (keepOrder === false) {
        this.showUnplannedUsers = this.userTasks.map((u) => u.uid);
      }

      if (keepOrder === false) {
        this.userTasks.forEach((u) => {
          u.showUnplanned = false;
          this.sortUserTasks(u);
        });
      }

      this.processData(this.userTasks, previousDays, date_to);

      this.isLoading = false;

      this.handleTaskSchedule(this.taskScheduleSearchTeam);
      this.isRefreshing = false;
    },
    sortUserTasks(u) {
      const unplanned = [];
      const planned = [];

      u.tasks.forEach((t) => {
        if (t.hasPlanned === true) {
          planned.push(t);
        } else {
          unplanned.push(t);
        }
      });

      unplanned.sort((a, b) => {
        // Sort by client_name, title, then by task_type_name
        const clientComparison = a.client_name.localeCompare(b.client_name);
        if (clientComparison !== 0) return clientComparison;

        const titleComparison = a.title?.localeCompare(b.title);
        if (titleComparison !== 0) return titleComparison;
        return a.task_type_name.localeCompare(b.task_type_name);
      });

      if (unplanned.length > 0) {
        unplanned[unplanned.length - 1].isBottomUnplanned = true;
      }

      u.tasks = [...unplanned, ...planned];
    },
    processData(users, previousDays, date_to) {
      var projectManagers = [];
      users.forEach((u) => {
        if (u.tasks) {
          u.tasks.forEach((t) => {
            projectManagers.push(t.project_manager);
          });
        }
      });

      projectManagers = new Set(projectManagers);
      projectManagers = Array.from(projectManagers);
      projectManagers.forEach((p) => {
        var u = this.userTasks.find((a) => a.uid === p);
        if (u) {
          u.type = "Project Manager";
          this.projectManagers.push(u);
        }
      });

      users = this.preprocessUsers(users);

      this.findMaxMinDate(users);
      this.users = users;

      this.previousDays += previousDays;

      this.maxDate = new Date(date_to);
      this.days = this.getDates(
        new Date().addDays(this.previousDays),
        new Date(this.maxDate)
      );

      if (this.isTimesheetView == 1) {
        // this.days must start on a monday
        while (this.days[Object.keys(this.days)[0]].dayAbbr !== "mon") {
          delete this.days[Object.keys(this.days)[0]];
        }

        // delete days that are more than a month in the future
        while (
          new Date(Object.keys(this.days)[Object.keys(this.days).length - 1]) >
          new Date().addDays(30)
        ) {
          delete this.days[
            Object.keys(this.days)[Object.keys(this.days).length - 1]
          ];
        }
      }

      this.firstDate = Object.keys(this.days)[0];
      this.lastDate = Object.keys(this.days)[Object.keys(this.days).length - 1];

      this.weeks = this.getWeeks(this.days);

      var monthHeaders = {};
      var c = 0;

      Object.keys(this.days).forEach((key) => {
        if (this.days[key].date === 1 || key === this.firstDate) {
          var s = this.buildShortDate(new Date(key).addDays(c * -2));
          monthHeaders[s] = {
            d: s,
            m: this.monthNames[this.days[key].month],
            y: this.days[key].year,
          };
          c++;
        }
      });

      this.monthHeaders = monthHeaders;

      this.prevLoading = false;
      this.isLoading = false;
    },
    findMaxMinDate(users) {
      users.forEach((u) => {
        u.tasks.forEach((t) => {
          if (t.date_due) {
            if ((t.date_due.date > this.maxDate) | !this.maxDate) {
              this.maxDate = t.date_due.date;
            }
          }
          if ((t.date_start?.date < this.minDate) | !this.minDate) {
            this.minDate = t.date_start?.date;
          }
        });
      });
    },
    createTaskLookup(tasks) {
      return tasks.reduce((acc, task) => {
        acc[task.uid] = task;
        if (task.resource_plan) {
          task.resourcePlanByDate = Object.fromEntries(
            task.resource_plan.map((plan) => [plan.date, plan])
          );
        }
        return acc;
      }, {});
    },
    preprocessUsers(users) {
      return users.map((user) => {
        const processedUser = { ...user };

        // Precompute working hours and workingDays for each day
        processedUser.workingHoursByDay = {};
        processedUser.workingDays = {};
        Object.entries(user.working_hours || {}).forEach(([day, hours]) => {
          processedUser.workingDays[day] = hours.on;
          if (hours.on) {
            const [h, m] = hours.hours.split(":");
            processedUser.workingHoursByDay[day] = {
              on: true,
              minutes: +h * 60 + +m,
            };
          } else {
            processedUser.workingHoursByDay[day] = { on: false, minutes: 0 };
          }
        });

        // Precompute task data
        processedUser.taskData = {};
        user.tasks.forEach((task) => {
          task.resource_plan.forEach((plan) => {
            if (!processedUser.taskData[plan.date]) {
              processedUser.taskData[plan.date] = { total: 0, captured: 0 };
            }
            processedUser.taskData[plan.date].total +=
              parseFloat(plan.minutes) || 0;
            processedUser.taskData[plan.date].captured +=
              parseFloat(plan.minutes_worked) || 0;
          });
        });

        // Precompute over hours
        processedUser.overHours = {};
        Object.entries(processedUser.taskData).forEach(([date, data]) => {
          const dayOfWeek = new Date(date).getDay();
          const dayAbbr = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"][
            dayOfWeek
          ];
          const workingHours = processedUser.workingHoursByDay[dayAbbr].minutes;
          processedUser.overHours[date] = Math.max(
            0,
            data.total - workingHours
          );
        });

        return processedUser;
      });
    },
    mouseUp() {
      this.isDragging = false;
      this.handleRequestQueue();
    },
    handleRightClick(event) {
      event.preventDefault();
      event.stopPropagation();
      return false;
    },
    handleRequestQueue() {
      if (this.selectedCells.length > 0 && !this.isSending) {
        this.isSending = true;

        var url = "";
        if (this.selectedCells[0].locking) {
          url =
            process.env.VUE_APP_ROOT_API +
            "/v1/tasks/" +
            this.selectedObject.task_uid +
            "/allocate/lock?date=" +
            this.selectedObject.date +
            "&user=" +
            this.selectedObject.user_uid;
        } else {
          var dateString = JSON.parse(
            JSON.stringify(this.selectedCells[0].date)
          );

          if (this.selectedCells[0].date.length > 1) {
            var resource_plan = this.users
              .find((a) => a.uid === this.selectedCells[0].uid)
              .tasks.find(
                (b) => b.uid === this.selectedCells[0].tid
              ).resource_plan;

            var adding =
              resource_plan.findIndex(
                (a) => a.date == this.selectedCells[0].date[0]
              ) == -1;

            for (var x = dateString.length - 1; x >= 0; x--) {
              if (
                (adding &&
                  resource_plan.findIndex((a) => a.date === dateString[x]) !=
                    -1) ||
                (!adding &&
                  resource_plan.findIndex((a) => a.date === dateString[x]) ==
                    -1)
              ) {
                dateString.splice(x, 1);
              }
            }
          }

          url =
            process.env.VUE_APP_ROOT_API +
            "/v1/tasks/" +
            this.selectedCells[0].tid +
            "/allocate?date=" +
            dateString.join() +
            "&user=" +
            this.selectedCells[0].uid;

          if (this.selectedCells[0].time) {
            url += "&time=" + this.selectedCells[0].time;
          }
        }

        this.$axios.get(url).then((res) => {
          var urlParams = new URLSearchParams(res.request.responseURL);
          var params = [];
          urlParams.forEach((a) => {
            params.push(a);
          });

          var userUid = params[1];
          var taskUid = res.data.data.uid;

          var plan = res.data.data.users.find(
            (a) => a.uid === userUid
          ).resource_plan;

          var user = this.users.find((a) => a.uid === userUid);
          var task = user.tasks.find((a) => a.uid == taskUid);

          var oldPlans = task.resource_plan.filter((a) => a.date < this.today);

          // merge old plan with new plan
          task.resource_plan = oldPlans.concat(plan);

          this.selectedCells.shift();
          this.isSending = false;
          this.handleRequestQueue();
        });
      }
    },
    refreshTaskSchedule() {
      this.getData(false, 0, true);
    },
    getWeeks(days) {
      var weeks = [];
      var week = {};
      var breakWeek = false;
      var firstMonday = null;

      Object.keys(days).forEach((key) => {
        if (days[key].dayAbbr === "mon") {
          firstMonday = true;
        }

        if (breakWeek === false && firstMonday === true) {
          week[key] = days[key];
          if (days[key].dayAbbr === "sun") {
            if (this.today < key) {
              breakWeek = true;
            }
            weeks.push(week);
            week = {};
          }
        }
      });
      if (Object.keys(week)?.length > 0) {
        weeks.push(week);
      }
      return weeks;
    },
    // handleResize() {
    //   this.isLoading = true;
    //   clearTimeout(this.resizeTimeout);
    //   this.resizeTimeout = setTimeout(() => {
    //     this.windowWidth = window.innerWidth; // Update the windowWidth
    //   }, 300); // Adjust delay as needed
    // },
    // async getDataWithResize() {
    //   // Call the getData method with your required parameters
    //   await this.getData(true, 0, true);
    // },
  },

  beforeDestroy() {
    this.$root.$off("refreshTaskSchedule", this.refreshTaskSchedule);
    this.$pusher.unsubscribe(this.account_uid);
    // window.removeEventListener("resize", this.handleResize); // Clean up the resize listener
  },

  async mounted() {
    this.settings = this.$store.getters.taskScheduleSettings;

    if (this.$route.query.userTaskUid) {
      this.userTaskUid = this.$route.query.userTaskUid;
      this.$router.replace({ query: {} });
    }

    await this.getData(true, this.isTimesheetView == 1 ? -21 : -4, false);
    this.subscribeChannel();

    if (this.userTaskUid) {
      var el = document.getElementById("ut-" + this.userTaskUid);
      if (el) {
        el.style.backgroundColor = "#F4FBEF";
        el.scrollIntoView({ behavior: "instant", block: "center" });
        el.style.transition = "background-color 3s";
        window.setTimeout(() => {
          el.style.backgroundColor = "white";
        }, 3000);
      }
    }
  },
  created() {
    this.$root.$on("refreshTaskSchedule", this.refreshTaskSchedule);
  },
  watch: {
    "settings.userCollapse"() {
      this.$store.dispatch("setTaskScheduleSettings", this.settings);
    },
    windowWidth() {
      this.getDataWithResize(); // Trigger getData when windowWidth changes
    },
  },

  name: "Home",

  metaInfo: {
    title: "Task Schedule",

    meta: [
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      { name: "description", content: "" },
      { name: "keywords", content: "" },
    ],
  },
};

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};
</script>
<style>
.resource-cell {
  position: relative;
  font-size: smaller;
  vertical-align: middle;
  border-style: solid;
  border-width: thin;
  min-width: 47px;
  min-height: 60px;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.date-cell {
  vertical-align: middle;
  min-width: 47px;
  /* border-top: 3px solid rgb(232, 236, 239); */
  min-height: 60px;
}

.header {
  left: 0;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
}

.task-header {
  background-color: white;
  vertical-align: middle;
  border-bottom: 1px solid rgb(232, 236, 239);
  border-right: 4px solid rgb(232, 236, 239);
}
.department-header {
  background-color: white;
}
.table-scroll {
  position: relative;

  z-index: 1;
  margin: auto;
  overflow: auto;
  /* overflow-x: hidden; */
  height: calc(100vh - 206px);
  padding-bottom: 2rem;
}
.table-scroll table {
  /* min-width: 1280px; */
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.table-scroll .resource-thead th {
  position: -webkit-sticky;
  background-color: white;
  position: sticky;
  z-index: 2;
  top: 0;
}

.table-scroll .resource-thead-days th {
  position: -webkit-sticky;
  position: sticky;
  z-index: 2;
  top: 18px;
}

.table-scroll .resource-thead-user th {
  border-top: 3px solid rgb(232, 236, 239);
  position: -webkit-sticky;
  background-color: #f8fafb;
  position: sticky;
  z-index: 2;
  top: 80px;
}

.resource-th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
}

.resource-thead-user .resource-th:first-child {
  z-index: 3;
  left: 0;
}

.resource-thead-days .resource-th:first-child {
  z-index: 3;
  left: 0;
}

.month-header {
  vertical-align: middle;
  position: -webkit-sticky;
  position: sticky;
  left: 275px;
  z-index: 2;
}

.cell-blank {
  cursor: default;
  border-radius: 0.4rem;
  border-color: rgb(242, 244, 246);
}

.cell-available {
  background-color: rgb(242, 244, 246);
  border-color: white;
  border-radius: 0.4rem;
}

.cell-active {
  background-color: #6dd230;
  border-radius: 0.4rem;
  border-color: white;
  color: white;
}

.cell-overun {
  background-color: #ffab2b;
  border-radius: 0.4rem;
  border-color: white;
  color: white;
}

.cell-sleep {
  border-radius: 0.4rem;
  background-repeat: no-repeat;
  background-position: center center;
  background-origin: content-box;
  border-color: rgb(242, 244, 246);
  color: #b9c5d0;
}

.cell-sleep-top-left {
  border-top-left-radius: 0.4rem;
}
.cell-sleep-top-right {
  border-top-right-radius: 0.4rem;
}

.cell-sleep-bottom-left {
  border-bottom-left-radius: 0.4rem;
}

.cell-sleep-bottom-right {
  border-bottom-right-radius: 0.4rem;
}

.cell-due-date {
  border-right-color: #98a9bc !important;
  border-right-width: 0.2rem !important;
  border-right-style: solid !important;
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}

/* .cell-due-date::after {
  content: "";
  position: absolute;
  top: 0;
  right: -5px;
  bottom: 0;
  width: 12px;
  cursor: col-resize;
} */

.cell-loading {
  border-color: white;
  /* animation: 2s placeHolderShimmer linear infinite; */
  border-radius: 0.4rem;
  background-size: 200% 100%;
}

.cell-loading.active {
  background: #6dd230;
}

.cell-loading.available {
  background: rgb(242, 244, 246);
}

.today {
  border-left-color: #4d7cfe !important;
  border-left-width: 0.15rem !important;
  border-left-style: solid !important;
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
}

.yesterday {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}

.past {
  pointer-events: none;
  background: linear-gradient(0deg, #778ca2 50%, #98a9bc 50%);
  border-radius: 0.4rem;
  border-color: white;
  color: #ced4d8;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.past-sleep {
  background: linear-gradient(180deg, #4d7cfe 50%, #b9c5d0 50%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.load-prev-icon {
  position: absolute;
  top: 20px;
  left: 3px;
  color: #98a9bc;
}

.load-next-icon {
  position: absolute;
  top: 20px;
  right: 3px;
  color: #98a9bc;
}

.tasks-schedule {
  position: relative;
  background: #fff;
  width: 100%;
}

.dragging {
  max-width: 10px !important;
  background-image: linear-gradient(
    to left,
    rgba(255, 0, 0, 0),
    rgba(255, 0, 0, 1)
  );
}
</style>
