<template>
  <div class="card pt-3">
    <div class="flex flex-wrap overflow-x-scroll justify-content-center sm:justify-content-between pb-1">
      <div class="flex text-center">
        <JobCostingFilter
          :draft="draft"
          :approved="approved"
          :invoiced="invoiced"
          :completed="completed"
          @filter="filter"
          :filters="jobFilters"
        />
      </div>
      <div class="flex text-center">
        <div>
          <Button
            class="p-button-text p-button-secondary"
            v-if="isCollapse"
            label="Expand"
            @click="toggleShowDescripton"
            iconPos="right"
            icon="las la-expand"
          />

          <Button
            v-else
            class="p-button-text p-button-secondary"
            iconPos="right"
            label="Collapse"
            @click="toggleShowDescripton"
            icon="las la-compress"
          />
        </div>
        <div class="mr-3">
          <FilterSearchButton v-model="search" />
        </div>
        <div v-if="!addGroup && items && items.length > 0">
          <Button
            v-can="'admin'"
            label="New Group"
            @click="insertGroup()"
            icon="las la-plus"
          />
        </div>

        <div
          v-if="job?.is_template == 1"
          class="ml-3"
        >
          <Button
            v-can="'admin'"
            label="New Job"
            @click="navJobFromTemplateMode(job)"
            icon="las la-plus"
          />
        </div>
        <div v-else-if="addGroup">
          <InputText
            ref="groupInput"
            v-model="newGroup"
            placeholder="Group Name"
            @keydown.enter="postGroup(newGroup)"
            @keydown.escape="
              newGroup = null;
              addGroup = false;
            "
          ></InputText>
          <Button
            icon="pi pi-check "
            :disabled="!newGroup"
            class="p-button-rounded grey-button ml-3"
            @click="postGroup(newGroup)"
          />
          <Button
            icon="pi pi-times "
            class="p-button-rounded grey-button ml-3"
            @click="
              newGroup = null;
              addGroup = false;
            "
          />
        </div>
      </div>
    </div>
    <div
      v-if="items && items.length < 1"
      class="flex justify-content-center sm:h-20rem align-items-center sm:p-6 text-16"
    >
      <div style="
          display: inline-block;
          padding: 30px 80px;
          background: #f8fafb;
          border-radius: 100px;
        ">
        <template v-if="!addGroup && items.length === 0 && !isLoading">
          <a
            class="cursor-pointer"
            @click="insertGroup()"
          >Create a group</a>
          to start adding items.</template>
        <div
          v-else
          class="flex"
        >
          <InputText
            ref="groupInput"
            v-model="newGroup"
            placeholder="Group Name"
            @blur="!newGroup ? (addGroup = false) : ''"
            @keydown.enter="postGroup(newGroup)"
            @keydown.escape="
              newGroup = null;
              addGroup = false;
            "
          ></InputText>
          <Button
            icon="pi pi-check "
            class="p-button-rounded grey-button ml-3"
            @click="postGroup(newGroup)"
          />
        </div>
      </div>
    </div>

    <draggable
      handle=".p-datatable-thead"
      :animation="140"
      :list="items"
      @end="onEndGroup($event)"
      :disabled="!!editGroup"
    >
      <div
        v-for="u in items"
        :key="u.uid"
        :style="
          minimized.indexOf(u.uid) > -1 || u.tasks?.length + u.costs?.length < 1
            ? 'margin-bottom:24px;'
            : 'margin-bottom:24px;'
        "
      >
        <div v-if="
            (hideCompletedItems &&
              u.items.filter((item) => item.is_complete == 0).length > 0) ||
            !hideCompletedItems
          ">
          <div v-if="
              ((jobFilters?.length > 0 || !!search) &&
                notFilteredItems(u)?.length > 0) ||
              (jobFilters?.length == 0 && !search)
            ">
            <div
              class="header-pointer"
              :id="u.uid"
              @click="handleHeaderClick"
            >
              <DataTable
                v-if="
                  (hideCompletedItems &&
                    u.items.filter((item) => item.is_complete == 0).length >
                      0) ||
                  !hideCompletedItems
                "
                :value="notFilteredItems(u)"
                @row-reorder="onRowReorder(u, ...arguments)"
                responsiveLayout="scroll"
                :rowClass="rowClass"
                :name="'table-' + u.uid"
                class="dt-class transition-dt no-empty-div"
                editMode="row"
                dataKey="uid"
                :tableClass="'drag-drop-table groupTableId-' + u.id"
              >
                <Column
                  :rowReorder="true"
                  :headerStyle="{ width: '1rem' }"
                  :reorderableColumn="false"
                ><template #header>
                    <i
                      v-if="minimized.indexOf(u.uid) > -1"
                      class="las la-plus cursor-pointer"
                    ></i>
                    <i
                      v-else
                      class="las la-minus cursor-pointer"
                    ></i>
                  </template>
                </Column>

                <Column field="title">
                  <template #header>
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div
                      v-else-if="editGroup !== u.uid"
                      class="font-light"
                      style="text-wrap: nowrap"
                    >
                      {{ groupTitle(u) }}
                    </div>
                    <template v-else>
                      <InputText
                        ref="groupInput"
                        v-model="editGroupTitle"
                        placeholder="Group Name"
                        @blur="handleBlur"
                        @keydown.enter="updateGroup(editGroupTitle, u)"
                        @keydown.escape="
                          editGroupTitle = null;
                          editGroup = false;
                        "
                      ></InputText>
                      <Button
                        icon="pi pi-check "
                        :disabled="!editGroupTitle"
                        class="p-button-rounded grey-button-flat p-button-text ml-1"
                        @click.stop="updateGroup(editGroupTitle, u)"
                      />
                      <Button
                        icon="pi pi-times "
                        class="p-button-rounded grey-button-flat p-button-text ml-1"
                        @click.stop="
                          editGroupTitle = null;
                          editGroup = false;
                        "
                      />
                    </template>
                  </template>
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div v-else>
                      <div
                        :class="
                          'p-1 ' +
                          (slotProps.data.is_complete == 1
                            ? 'font-light'
                            : 'text-link')
                        "
                        :id="'rowTask-' + slotProps.data.uid"
                      >
                        <span class="cursor-pointer flex align-items-center">
                          <i
                            style="font-size: 16px"
                            v-if="slotProps.data.is_complete == 1"
                            class="las la-check-circle grey mr-1 p-1 task-icon-complete mt-1"
                          />
                          <span
                            @click="nav(slotProps.data)"
                            v-if="slotProps.data.task_type_name"
                          >
                            {{ slotProps.data.task_type_name }}
                            <span v-if="slotProps.data.title != ''">
                              - {{ slotProps.data.title }}
                            </span>
                          </span>
                          <span
                            @click="
                              $store.dispatch('setSelectedObject', {
                                job: job,
                                group: u,
                                cost_uid: slotProps.data.uid,
                              });
                              $store.dispatch('setSliderView', 'JobCostSlider');
                            "
                            v-else
                          >
                            {{ slotProps.data.type_name }}
                            <span v-if="slotProps.data.title">
                              - {{ slotProps.data.title }}
                            </span>
                          </span>
                          <span class="invisible justify-content-end align-items-center cursor-pointer pl-2">
                            <i
                              style="
                                background-color: #f5f6f8;
                                border-radius: 50px;
                              "
                              v-if="slotProps.data.task_type_name"
                              @click.stop="navEditMode(slotProps.data)"
                              class="las la-pen i-18 p-1 grey mr-1 hover-border"
                            />
                            <i
                              style="
                                background-color: #f5f6f8;
                                border-radius: 50px;
                              "
                              v-else
                              class="las la-pen i-18 p-1 grey mr-1 hover-border"
                              @click.stop="
                                $store.dispatch('setSelectedObject', {
                                  job: job,
                                  group: u,
                                  cost_uid: slotProps.data.uid,
                                  mode: 'edit',
                                });
                                $store.dispatch(
                                  'setSliderView',
                                  'JobCostSlider'
                                );
                              "
                            />

                            <i
                              style="
                                background-color: #f5f6f8;
                                border-radius: 50px;
                              "
                              v-tooltip.bottom="{
                                value: 'Item has tracked time',
                                disabled:
                                  !slotProps.data.hours ||
                                  slotProps.data.hours === '00:00',
                              }"
                              @click.stop="
                                slotProps.data.hours &&
                                slotProps.data.hours !== '00:00'
                                  ? () => {}
                                  : deletePrompt(
                                      getItemTitle(slotProps.data),
                                      '/v1/' +
                                        (slotProps.data.task_type_name
                                          ? 'tasks'
                                          : 'costs') +
                                        '/' +
                                        slotProps.data.uid
                                    )
                              "
                              :class="
                                'las la-trash p-1 i-18  hover-border ' +
                                (slotProps.data.hours &&
                                slotProps.data.hours !== '00:00'
                                  ? 'disable-grey cursor-disable'
                                  : 'grey')
                              "
                            />
                          </span>
                        </span>
                      </div>

                      <div
                        v-if="
                          checkShouldShowDescription() &&
                          slotProps.data.description
                        "
                        class="p-1 flex flex-wrap font-light"
                      >
                        <div v-html="markDown(slotProps.data.description)"></div>
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  :header="minimized.indexOf(u.uid) < 0 ? 'Qty' : ''"
                  field=""
                  :styles="{
                    width: '6%',
                    whiteSpace: 'nowrap',
                    verticalAlign: 'top !important',
                  }"
                >
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div v-else>
                      <div class="col pr-0 ce-co">
                        {{
                          slotProps.data.estimated_hours
                            ? slotProps.data.estimated_hours
                            : slotProps.data.quantity
                        }}
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  :header="minimized.indexOf(u.uid) < 0 ? 'Price/Unit' : ''"
                  field="users"
                  :styles="{ width: '6%', verticalAlign: 'top !important' }"
                >
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div v-else>
                      <div class="col pr-0 ce-co">
                        {{
                          !!slotProps.data.unit_price
                            ? formatCurrency(
                                slotProps.data.unit_price,
                                job?.client?.currency?.description
                              )
                            : formatCurrency(
                                slotProps.data.rate,
                                job?.client?.currency?.description
                              ) + "/hr"
                        }}
                      </div>
                    </div>
                  </template>
                </Column>
                <Column
                  headerClass="header-right"
                  :header="minimized.indexOf(u.uid) < 0 ? 'Margin' : ''"
                  :styles="{ width: '6%', verticalAlign: 'top !important' }"
                >
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div v-else-if="slotProps.data && slotProps.data.margin">
                      <div class="col text-end pr-0 ce-co">
                        {{
                          formatCurrency(
                            slotProps.data.margin,
                            job?.client?.currency?.description
                          )
                        }}
                      </div>
                    </div>
                    <div
                      v-else
                      class="col text-end pr-3"
                    >-</div>
                  </template>
                </Column>

                <Column
                  headerClass="header-right"
                  :header="minimized.indexOf(u.uid) < 0 ? 'Total' : formatCurrency(getGroupTotal(u), job?.client?.currency?.description)"
                  field="active"
                  :styles="{ width: '6%', verticalAlign: 'top !important' }"
                >
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div v-else>
                      <div class="col text-end pr-0 ce-co">
                        {{
                          slotProps.data.total_price !== null
                            ? formatCurrency(
                                slotProps.data.total_price,
                                job?.client?.currency?.description
                              )
                            : formatCurrency(
                                slotProps.data.price,
                                job?.client?.currency?.description
                              )
                        }}
                      </div>
                    </div>
                  </template>
                </Column>

                <Column
                  :headerStyle="{ textWrap: 'nowrap' }"
                  :header="
                    minimized.indexOf(u.uid) < 0
                      ? 'Status'
                      : u.costs?.length +
                        u.tasks?.length +
                        ' item' +
                        (u.costs?.length + u.tasks?.length === 1 ? '' : 's')
                  "
                  field="active"
                  :styles="{ width: '10%', verticalAlign: 'top !important' }"
                >
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <div v-else>
                      <div class="col ce-co pl-0">
                        <div
                          class="text-12"
                          style="width: 30px"
                        >
                          <span
                            v-if="slotProps.data.status == 'invoiced'"
                            :style="'background: #FFF8EE !important;color: #FFB441 !important;borderRadius: 6px'"
                            class="p-2"
                          >{{
                              slotProps.data.issued === 1
                                ? "Issued"
                                : "Invoiced"
                            }}
                          </span>
                          <span
                            v-if="slotProps.data.status == 'draft'"
                            :style="'background: #E8ECEF !important;borderRadius: 6px;'"
                            class="p-2"
                          >{{ "Draft" }}</span>
                          <span
                            v-if="slotProps.data.status == 'approved'"
                            :style="'background: #F4FBEF !important;borderRadius: 6px;color: #6dd230'"
                            class="p-2"
                          >{{ "Active" }}</span>
                          <span
                            v-if="slotProps.data.status == 'complete'"
                            :style="'background: #E8ECEF !important;borderRadius: 6px;'"
                            class="p-2"
                          >{{ "Completed" }}</span>
                        </div>
                      </div>
                      <div
                        v-if="slotProps.data.invoiced_amount"
                        class="surface-300 border-round overflow-hidden w-full mt-1 mr-3"
                        style="height: 6px"
                      >
                        <div
                          class="h-full"
                          :style="getProgressStyle(slotProps.data)"
                        />
                      </div>
                    </div>
                  </template>
                </Column>

                <Column
                  headerClass="header-right"
                  :header="minimized.indexOf(u.uid) < 0 ? '...' : ''"
                  field="active"
                  :styles="{ width: '5%', verticalAlign: 'top !important' }"
                >
                  <template #body="slotProps">
                    <Skeleton v-if="isLoading"></Skeleton>
                    <!-- <div v-else-if="slotProps.data.invoiced === 1">
                    <AInputSwitch :value="1" class="green" />
                  </div> -->
                    <div v-else>
                      <AInputSwitch
                        :value="slotProps.data.is_approved"
                        @input="updateItemStatus(slotProps.data)"
                        class="green"
                      />
                    </div>
                  </template>
                </Column>
                <Column
                  bodyStyle="text-align:right"
                  :styles="{ width: '1rem', verticalAlign: 'top !important' }"
                >
                  <template #header>
                    <div
                      v-if="!editGroup && !isLoading"
                      class="flex justify-content-end align-items-center cursor-pointer"
                    >
                      <i
                        @click.stop="
                          editGroup = u.uid;
                          editGroupTitle = u.title;
                        "
                        class="las hover-show la-pen i-20 grey mr-1"
                      />
                      <i
                        @click.stop="
                          deletePrompt(u.title, '/v1/tasks/group/' + u.uid)
                        "
                        class="las la-trash i-20 grey hover-show"
                      />
                    </div>
                  </template>
                </Column>
                <ColumnGroup type="footer">
                  <Row>
                    <Column
                      :colspan="4"
                      :styles="{ background: 'white' }"
                    >
                      <template #footer>
                        <Button
                          label="Add Task"
                          style="color: #b9c5d0"
                          class="p-button-text p-button-secondary ml-3"
                          @click="
                      unsetTask();
                      setitemGroup(u.uid);
                      $store.dispatch('setSliderView', 'TaskSlider');
                    "
                          icon="las la-plus"
                        />
                        <Button
                          label="Add Cost"
                          style="color: #b9c5d0"
                          class="p-button-text p-button-secondary ml-3"
                          @click="
                      setDefaultJobApprove();
                      $store.dispatch('setSelectedObject', {
                        job: job,
                        group: u,
                      });
                      $store.dispatch('setSliderView', 'JobCostSlider');
                    "
                          icon="las la-plus"
                        />
                      </template>
                    </Column>

                    <Column
                      :colspan="1"
                      :styles="{ background: 'white' }"
                    > <template #footer>
                        <div
                          class=" text-end"
                          style="font-weight:500"
                        >
                          Total
                        </div>
                      </template></Column>
                    <Column
                      :colspan="1"
                      :styles="{ background: 'white' }"
                    > <template #footer>
                        <div
                          class=" text-end"
                          style="font-weight:500"
                        >

                          {{ formatCurrency(getGroupTotal(u), job?.client?.currency?.description) }}
                        </div>
                      </template></Column>
                  </Row>
                </ColumnGroup>
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    </draggable>
    <TotalTableFooter
      :subTotal="jobItemTotals.sub"
      :taxRate="parseFloat(jobItemTotals.tax)"
      :profitMargin="jobItemTotals.margin"
      :total="jobItemTotals.total"
      :currencySymbol="job?.client?.currency?.description"
      v-if="jobItemTotals"
    ></TotalTableFooter>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import {
  fetchJob,
  fetchJobItems,
  state,
} from "../../../services/data_service.js";
import TotalTableFooter from "@/application/Finance/components/TotalTableFooter.vue";
import { temp_state } from "../../../services/temp_state_service.js";
import { FilterMatchMode } from "primevue/api";
import JobCostingFilter from "@/application/Job/components/JobCostingFilter.vue";

import debounce from "lodash/debounce";
export default {
  name: "Home",
  components: { draggable, JobCostingFilter, TotalTableFooter },
  props: {
    isActive: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      isCollapse: true,
      jobFilters: [],
      addGroup: null,
      isLoading: false,
      newGroup: null,
      updatedItemsCount: 0,
      debouncedRefresh: null,
      editGroup: null,
      editGroupTitle: null,
      hideCompletedItems: false,
      debouncedErrorHandler: null,
      itemTagUID: null,
      minimized: [],
      search: null,
      path: [
        {
          header: "Items",
          icon: "la-check",
        },

        {
          header: "Notes",
          icon: "la-comments",
        },
        {
          header: "Files",
          icon: "la-folder-open",
        },
        {
          header: "Automations",
          icon: "la-robot",
        },
      ],
    };
  },
  async mounted() {
    await this.loadData();
    this.handleRowTableDrag();
  },
  created() {
    this.$bus.on("refreshData", this.loadData);
    this.debouncedRefresh = debounce(this.refreshDataAndNotify, 1000);
    this.debouncedErrorHandler = debounce(this.handleErrors, 300);
  },
  beforeDestroy() {
    this.$bus.off("refreshData", this.loadData);
    if (this.debouncedRefresh) {
      this.debouncedRefresh.cancel();
    }
    if (this.debouncedErrorHandler) {
      this.debouncedErrorHandler.cancel();
    }
    // Clean up event listeners
    document.querySelectorAll(".row-accessories-drag").forEach((el) => {
      el.removeEventListener("dragstart", this.handleDragStart);
      el.removeEventListener("dragend", this.handleDragEnd);
    });

    document.querySelectorAll(".drag-drop-table").forEach((el) => {
      el.removeEventListener("dragover", this.handleDragOver);
      el.removeEventListener("dragleave", this.handleDragLeave);
      el.removeEventListener("drop", this.handleDrop);
    });
  },

  methods: {
    navJobFromTemplateMode(ev) {
      ev.job_from_template = 1;
      this.$store.dispatch("setSelectedObject", ev);
      this.$store.dispatch("setSliderView", "JobSlider");
    },
    getGroupTotal(group) {
      let total = 0;
      group.tasks.forEach((item) => {
        total += item.total_price;
      });

      group.costs.forEach((item) => {
        total += item.total_price;
      });

      return total;
    },
    markDown(text) {
      return text
        .replace(/<\/p><p>/g, "<br>") // Replace all </p><p> with <br>
        .replace(/<p>/g, "") // Remove all <p> tags
        .replace(/<\/p>/g, "") // Remove all </p> tags
        .replace(/\n/g, "<br>"); // Replace all newlines with <br>
    },
    handleRowTableDrag() {
      let draggedRow = null;
      let originalTable = null;

      // Add dragstart event listener to rows with the "row-accessories-drag" class
      document.querySelectorAll(".row-accessories-drag").forEach((el) => {
        el.setAttribute("draggable", "true");

        el.addEventListener("dragstart", (e) => {
          draggedRow = e.target;
          originalTable = e.target.closest("table"); // Store the original parent table
          e.target.classList.add("dragging");
          e.dataTransfer.effectAllowed = "move";
        });

        el.addEventListener("dragend", (e) => {
          e.target.classList.remove("dragging");
        });
      });

      // Add dragover and drop event listeners to every table on the page
      document.querySelectorAll(".drag-drop-table").forEach((table) => {
        table.addEventListener("dragover", (e) => {
          e.preventDefault(); // Necessary to allow a drop
          e.dataTransfer.dropEffect = "move";

          if (table !== originalTable) {
            table.classList.add("dragover"); // Only add if it's a different table
          }
        });

        table.addEventListener("dragleave", (e) => {
          table.classList.remove("dragover");
        });

        table.addEventListener("drop", (e) => {
          e.preventDefault();
          table.classList.remove("dragover");

          if (draggedRow && table !== originalTable) {
            // Move the dragged row to the new table's tbody
            table.querySelector("tbody").appendChild(draggedRow);

            // get div with id that contains rowTask-
            const rowTask = draggedRow.querySelector("div[id^='rowTask-']");
            // extract the id from the div
            const task_id = rowTask.id.split("-")[1];

            // get the group id from the table class
            const group_id = table.className.split(" ")[1].split("-")[1];

            var form = new FormData();

            form.append("group", group_id);

            this.$axios.post(
              process.env.VUE_APP_ROOT_API + "/v1/tasks/" + task_id,
              form,
              {
                headers: {
                  "Content-type": "application/x-www-form-urlencoded",
                },
              }
            );

            draggedRow = null; // Reset the dragged row
            originalTable = null; // Reset the original table
          }
        });
      });
    },

    handleBlur() {
      setTimeout(() => {
        this.editGroup = false;
      }, 100);
    },
    filter(f) {
      var i = this.jobFilters.indexOf(f);
      if (i >= 0) {
        this.jobFilters.splice(i, 1);
      } else {
        this.jobFilters.push(f);
      }
    },
    notFiltered(arr) {
      var retArr = [];
      arr.forEach((i) => {
        if (this.jobFilters?.length > 0) {
          if (this.jobFilters.indexOf("draft") >= 0 && i.status == "draft") {
            retArr.push(i);
          } else if (
            this.jobFilters.indexOf("approved") >= 0 &&
            i.status == "approved"
          ) {
            retArr.push(i);
          } else if (
            this.jobFilters.indexOf("invoiced") >= 0 &&
            i.status == "invoiced"
          ) {
            retArr.push(i);
          } else if (
            this.jobFilters.indexOf("completed") >= 0 &&
            i.is_complete == 1
          ) {
            retArr.push(i);
          }
        } else {
          retArr.push(i);
        }
      });

      if (this.search) {
        retArr = retArr.filter((i) => {
          return (
            i.task_type_name
              ?.toLowerCase()
              .includes(this.search.toLowerCase()) ||
            i.title?.toLowerCase().includes(this.search.toLowerCase()) ||
            i.type_name?.toLowerCase().includes(this.search.toLowerCase()) ||
            i.description?.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      }

      return retArr;
    },

    async loadData() {
      if (!state.jobItems) {
        this.isLoading = true;
        state.jobItems = [];
        for (let index = 0; index < 3; index++) {
          state.jobItems.push({
            tasks: [],
            costs: [],
          });
          for (let index2 = 0; index2 < 2; index2++) {
            state.jobItems[index].tasks.push({});
          }
          for (let index2 = 0; index2 < 1; index2++) {
            state.jobItems[index].costs.push({});
          }
        }
      }
      await fetchJobItems(this.$route.params.uid);
      this.isLoading = false;
    },
    updateItemStatus(item) {
      const originalStatus = item.status;
      const originalIsApproved = item.is_approved;

      // Update the status optimistically
      item.status = item.status === "approved" ? "draft" : "approved";
      item.is_approved = item.is_approved === 1 ? 0 : 1;

      this.updatedItemsCount++;

      this.$axios
        .get(
          `${process.env.VUE_APP_ROOT_API}/v1/jobs/${this.job.uid}/approve/${item.uid}`
        )
        .then(() => {
          this.debouncedRefresh();
        })
        .catch((error) => {
          console.error(error);
          // Revert the status
          item.status = originalStatus;
          item.is_approved = originalIsApproved;
          this.updatedItemsCount--;

          // Call the debounced error handler
          this.debouncedErrorHandler(item);
        });
    },
    handleErrors(item) {
      this.$toast.add({
        severity: "error",
        summary: "Error Updating Item Status",
        detail: `Failed to update status for ${
          item.title || "item"
        }. Please try again.`,
        life: 3000,
      });
    },

    refreshDataAndNotify() {
      fetchJob(this.$route.params.uid);
      if (this.updatedItemsCount > 0) {
        this.$toast.add({
          severity: "success",
          summary: "Items Status Updated",
          detail: `${this.updatedItemsCount} item${
            this.updatedItemsCount > 1 ? "s" : ""
          } updated`,
          life: 3000,
        });
      }
      this.updatedItemsCount = 0; // Reset the count
    },
    getItemTitle(obj) {
      return obj.task_type_name ?? obj.type_name;
    },
    expandTags() {
      temp_state.tagsExpanded = !temp_state.tagsExpanded;
    },

    getStyle(c) {
      if (c && c.charAt(0) !== "#") {
        c = "#" + c;
      }
      return "background-color: " + c + "; color:white;";
    },
    checkShouldShowDescription() {
      if (this.isCollapse) {
        return false;
      } else {
        return true;
      }
    },
    toggleShowDescripton() {
      this.isCollapse = !this.isCollapse;
    },
    handleHeaderClick(ev) {
      var isHeader = false;
      var uid = null;
      if (ev && ev.composedPath) {
        const path = ev.composedPath();

        for (let element of path) {
          if (isHeader && uid) {
            break;
          }
          if (element.id) {
            uid = element.id;
          }
          if (element.tagName === "TH") {
            isHeader = true;
          }
          if (
            element.tagName === "TBODY" ||
            element.tagName === "INPUT" ||
            element.className === "p-datatable-footer"
          ) {
            break;
          }
        }
      }
      if (uid) {
        if (uid == this.editGroup) {
          return;
        }
        this.expand(uid);
      }
    },
    dateRange(job) {
      let date_start = new Date(job?.date_start?.date);
      let date_due = new Date(job?.date_due?.date);

      if (date_start.getFullYear() == date_due.getFullYear()) {
        return (
          this.$moment(job.date_start.nice, "DD MMM YYYY").format("DD MMM") +
          " - " +
          this.$moment(job.date_due.nice, "DD MMM YYYY").format("DD MMM 'YY")
        );
      }
      return (
        this.$moment(job.date_start.nice, "DD MMM YYYY").format("DD MMM 'YY") +
        " - " +
        this.$moment(job.date_due.nice, "DD MMM YYYY").format("DD MMM 'YY")
      );
    },
    getDateRangeClass(date) {
      switch (date.tag_class) {
        case "green":
          return "in-progress-job";
        case "red":
          return "overdue-job";
        case "orange":
          return "tomorrow-job";
      }
      return "in-progress-job";
    },
    insertGroup() {
      this.addGroup = !this.addGroup;
      this.$nextTick((r) => {
        this.$refs.groupInput.$el.focus();
      });
    },
    setitemGroup(uid) {
      temp_state.defaultJobApprove = true;
      state.itemGroup = uid;
    },
    getHeaderStyle(route) {
      if (route === "Items") {
        return "font-weight: bold; color: #4D7CFE";
      }
    },
    setDefaultJobApprove() {
      temp_state.defaultJobApprove = false;
    },
    unsetTask() {
      this.$store.dispatch("setSelectedObject", null);
      state.task = null;
      state.amendments = [];
      state.taskUsers = [];
    },
    getUsers(item) {
      this.$store.dispatch("setSelectedObject", { uid: item.uid });
      this.$store.dispatch("setPopupDialog", "UserSelect");
    },
    editDelete(u) {},
    onColReorder() {
      this.$toast.add({
        severity: "success",
        summary: "Column Reordered",
        life: 3000,
      });
    },

    rowClass(data) {
      return "row-accessories row-accessories2 row-accessories-drag no-padding-row";
    },
    getProgressStyle(item) {
      var progress =
        item.invoiced_amount && item.price
          ? (item.invoiced_amount / item.price) * 100
          : 0;
      if (item.total_price && item.total_price !== 0) {
        progress = (item.invoiced_amount / item.total_price) * 100;
      }

      if (progress >= 100) {
        return "background: #fe4d97;width:100%";
      } else if (progress >= 60) {
        return "background: #ffab2b;width:" + progress + "%";
      } else {
        return "background: #6dd230;width:" + progress + "%";
      }
    },
    navEditMode(ev, mode) {
      state.editItemMode = true;
      state.editTaskMode = true;
      this.$store.dispatch("setSelectedObject", {
        uid: ev.uid,
        mode: mode,
      });
      this.$store.dispatch("setSliderView", "TaskSlider");
    },
    nav(ev) {
      this.$store.dispatch("setSelectedObject", {
        uid: ev.uid,
      });
      this.$store.dispatch("setSliderView", "TaskSlider");
    },
    postGroup(n) {
      if (n && n.length > 0) {
        const job_uid = this.$route.params.uid;
        var form = new FormData();
        form.append("title", n);
        const title = n;
        form.append("job", job_uid);
        this.$axios
          .post(process.env.VUE_APP_ROOT_API + "/v1/tasks/group", form, {
            headers: { "Content-type": "application/x-www-form-urlencoded" },
          })
          .then((response) => {
            (this.newGroup = null),
              this.$toast.add({
                severity: "success",
                summary: "Group Saved",
                detail: title + " has been added",
                life: 3000,
              });
            this.addGroup = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error);
          });
      }
    },
    updateGroup(n, t) {
      if (n && n.length > 0) {
        var form = new FormData();
        form.append("title", n);
        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API + "/v1/tasks/group/" + this.editGroup,
            form,
            {
              headers: { "Content-type": "application/x-www-form-urlencoded" },
            }
          )
          .then((response) => {
            this.editGroup = false;
            t.title = n;
            this.$toast.add({
              severity: "success",
              summary: "Group Saved",
              detail: n + " has been updated",
              life: 3000,
            });
            this.addGroup = false;
          })
          .catch((error) => {
            this.isLoading = false;
            console.error(error);
          });
      }
    },
    onEndGroup(e) {
      var form = new FormData();
      const job_uid = this.$route.params.uid;
      form.append("job", job_uid);
      var sortlist = "";
      this.items.forEach((value) => {
        sortlist += value.uid + ",";
      });
      form.append("sortlist", sortlist);
      this.$axios
        .post(process.env.VUE_APP_ROOT_API + "/v1/tasks/group/sort", form, {
          headers: { "Content-type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          //
        })
        .catch((error) => {
          console.error(error);
        });
    },
    expand(uid) {
      const index = this.minimized.indexOf(uid);

      var table = document.getElementsByName("table-" + uid)[0];

      if (!table) {
        return;
      }

      var rows = table.getElementsByTagName("tr");
      var height = 0;
      for (var i = 0; i < rows.length; i++) {
        height += rows[i].getBoundingClientRect().height;
      }

      if (index > -1 && table) {
        table.style.maxHeight = "100%";
      } else {
        table.style.maxHeight = height + "px";
        setTimeout(() => {
          table.style.overflow = "hidden";
          table.style.maxHeight = "42px";
        }, 300);
      }

      if (index > -1) {
        this.minimized.splice(index, 1); // 2nd parameter means remove one item only
      } else {
        this.minimized.push(uid);
      }
    },
    onRowReorder(e, ev) {
      e.items = ev.value;
      var form = new FormData();

      const job_uid = this.$route.params.uid;
      form.append("item", ev.value[ev.dragIndex]);
      form.append("group", e.uid);
      form.append("job", job_uid);
      var sortlist = "";

      e.items = ev.value;
      ev.value.forEach((value) => {
        sortlist += value.uid + ",";
      });
      form.append("sortlist", sortlist);
      this.$axios
        .post(process.env.VUE_APP_ROOT_API + "/v1/tasks/group/move", form, {
          headers: { "Content-type": "application/x-www-form-urlencoded" },
        })
        .then((response) => {
          //
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  beforeMount() {
    temp_state.tagsExpanded = false;
  },
  computed: {
    getTableStyle() {
      return (u) => {
        var table = document.getElementsByName("table-" + u.uid)[0];
        var height = 0;
        if (table) {
          var rows = table.getElementsByTagName("tr");
          for (var i = 0; i < rows.length; i++) {
            height += rows[i].getBoundingClientRect().height;
          }
        }

        return this.minimized.indexOf(u.uid) > -1
          ? "height:42px;overflow: hidden;"
          : "height:100%;";
      };
    },
    groupTitle() {
      return (u) => {
        if (u.title) {
          return u.title;
        } else {
          return "No Group";
        }
      };
    },
    notFilteredItems() {
      return (u) => this.notFiltered([...u.tasks, ...u.costs]);
    },
    jobItemTotals() {
      return state.jobItemTotals;
    },
    job() {
      return state.job;
    },
    tags_expanded() {
      return temp_state.tagsExpanded;
    },
    user() {
      return this.$store.getters.user;
    },

    items() {
      return state.jobItems || [];
    },
    draft() {
      var o = 0;
      this.items?.forEach((a) => {
        a.costs.forEach((a) => {
          if (a.status == "draft") o++;
        });
        a.tasks.forEach((a) => {
          if (a.status == "draft") o++;
        });
      });
      return o;
    },
    invoiced() {
      var o = 0;
      this.items?.forEach((a) => {
        a.costs.forEach((a) => {
          if (a.status == "invoiced") o++;
        });
        a.tasks.forEach((a) => {
          if (a.status == "invoiced") o++;
        });
      });
      return o;
    },
    completed() {
      var o = 0;
      this.items?.forEach((a) => {
        a.costs.forEach((a) => {
          if (a.is_complete == 1) o++;
        });
        a.tasks.forEach((a) => {
          if (a.is_complete == 1) o++;
        });
      });
      return o;
    },
    approved() {
      var o = 0;
      this.items?.forEach((a) => {
        a.costs.forEach((a) => {
          if (a.is_approved == 1) o++;
        });
        a.tasks.forEach((a) => {
          if (a.is_approved == 1) o++;
        });
      });
      return o;
    },
  },
  watch: {
    async isActive(n, o) {
      if (n === true) {
        this.loadData();
      }
    },
  },
};
</script>
<style>
.slide-fade-enter-active {
  transition: all 0.4s ease-in;
  transition-delay: 0.2s !important;
}

.slide-fade-enter-from {
  transform: translateX(50px);
  opacity: 0;
}
.task-icon-complete {
  color: #6dd230 !important;
}
.grey-out {
  opacity: 0.5 !important;
  /* background-color: rgb(249, 249, 249) !important; */
}
</style>
