<template>
  <div
    class="container-padding"
    align="left"
  >
    <div v-if="isLoading">
      <DeskLoading></DeskLoading>
    </div>
    <template v-else>
      <Menu
        ref="menuMain"
        :popup="true"
        :model="mainItems"
      />
      <Menu
        ref="menu"
        :popup="true"
        :model="items"
      />

      <PausedTasks />
      <DeskEntry
        :ghostTask="ghostTask"
        :task="myDesk"
        :myTasks="myTasks"
      />

      <!-- <MySchedule></MySchedule> -->

      <simplebar
        style="overflow-y: hidden"
        ref="board"
        v-full-height
      >
        <div class="flex">
          <DeskColumn
            @ghostDesk="handleGhostDesk"
            v-model="t.list"
            v-for="t in tasks"
            :key="t.name"
            :listId="t.id"
            :class="t.class"
            :heading="t.name"
            @toggleMenu="toggleMenu"
            :expand="expand"
          />
        </div>
      </simplebar>
    </template>
  </div>
</template>

<script>
import { state, fetchMyTasks } from "../../../services/data_service.js";
import { temp_state } from "../../../services/temp_state_service";
// import MySchedule from "../components/MySchedule";

import DeskColumn from "../components/DeskColumn";
import DeskLoading from "../components/DeskLoading";
import DeskEntry from "../components/DeskEntry";
import PausedTasks from "../components/PausedTasks";

import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
export default {
  data() {
    return {
      // tasks_pending: null,
      screenHeight: null,

      isLoading: false,
      errors: [],
      dragging: false,
      expand: false,
      ghostTask: null,
      tasks: null,
      mainItems: [
        {
          label: "Hide time increments",
        },
        {
          label: "Menu item",
        },
        {
          label: "Menu item",
        },
      ],
      items: [
        {
          label: "Commit Time",
        },
        {
          label: "Done for Today",
        },
        {
          label: "Mark Completed",
        },
      ],
      placeHolder: null,
    };
  },

  beforeMount() {
    temp_state.tagsExpanded = false;
  },
  beforeDestroy() {
    this.$pusher.unsubscribe(this.account_uid);
    this.$bus.off("refreshData", fetchMyTasks);
  },
  created() {
    this.$bus.on("refreshData", fetchMyTasks);
  },
  methods: {
    getReviewColumnClass(colName) {
      if (colName === "review") {
        if (this.myTasks.review.length > 0) {
          return "col-11 lg:col-3 md:col-5 sm:col-9 pr-3";
        }
        return "hidden";
      }
      if (this.myTasks.review.length > 0) {
        return "col-11 lg:col-3 md:col-5 sm:col-10 pr-3";
      }
      return "col-11 lg:col-4 md:col-5 sm:col-10 pr-3";
    },
    getTimer() {
      if (this.myDesk?.uid) {
        this.$axios
          .get(
            process.env.VUE_APP_ROOT_API +
              "/v1/tasks/" +
              this.myDesk?.uid +
              "/timer/get",
            {}
          )
          .then((response) => {
            this.$store.dispatch("setTimer", response.data.data);
            this.isLoading = false;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    handleGhostDesk(ev) {
      this.ghostTask = ev;
    },
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    },
    subscribeChannel() {
      var channel = this.$pusher.subscribe(this.account_uid);
      channel.bind("task", (data) => {
        if (data === 1 || data === 0) {
          fetchMyTasks();
        }
        // if (
        //   this.sliderView !== "TaskSlider" &&
        //   data.users &&
        //   data.users.find((a) => a.uid === this.user.uid)
        // ) {
        fetchMyTasks();
        // }
      });
    },
    setTasks() {
      this.ghostTask = null;
      var today = this.myTasks.today.filter(
        (a) => a.is_complete !== 1 && a.in_review !== 1
      );

      if (this.myTasks) {
        this.tasks = [
          {
            id: 1,
            name: "My Tasks",
            list: this.myTasks.tasks.sort((a, b) =>
              a.order > b.order ? -1 : 1
            ),
            class: this.getReviewColumnClass(),
          },
          {
            id: 2,
            name: "For Today",
            list: today.sort((a, b) => (a.order > b.order ? -1 : 1)),
            class: this.getReviewColumnClass(),
          },
          {
            id: 5,
            name: "In Review",
            list: this.myTasks.review.sort((a, b) =>
              a.order > b.order ? -1 : 1
            ),
            class: this.getReviewColumnClass("review"),
          },
          {
            id: 3,
            name: "Completed",
            list: this.myTasks.completed.sort((a, b) =>
              a.order > b.order ? -1 : 1
            ),
            class: this.getReviewColumnClass(),
          },
        ];
      }
    },
  },

  computed: {
    getHeight() {
      return (
        "height:" + (this.screenHeight - 1325) + "px;background-color:green"
      );
    },

    user() {
      return this.$store.getters.user;
    },
    account_uid() {
      return this.user?.account_uid;
    },
    draggingInfo() {
      return this.dragging ? "under drag" : "";
    },
    user_uid() {
      return this.$store.getters.user.uid;
    },
    deskOrder() {
      return this.$store.getters.deskOrder;
    },
    myDesk() {
      if (state.myDesk === undefined) {
        return this.ghostTask;
      }
      return state.myDesk;
    },
    myTasks() {
      return state.myTasks;
    },
    sliderView() {
      return this.$store.getters.sliderView;
    },

    tasks_review() {
      return this.myTasks.review;
    },
    tasks_completed() {
      return this.myTasks.completed;
    },
  },
  async mounted() {
    this.isLoading = true;
    this.subscribeChannel();

    if (this.myTasks == null) {
      this.tasks = [
        {
          id: 1,
        },
        {
          id: 2,
        },
        {
          id: 3,
        },
        {
          id: 5,
        },
      ];
      this.isLoading = true;
    } else {
      this.setTasks();
    }
    await fetchMyTasks();

    this.getTimer();
    this.setTasks();
    this.isLoading = false;
  },
  name: "Home",
  components: {
    DeskEntry,
    DeskColumn,
    simplebar,
    PausedTasks,
    // MySchedule,
    DeskLoading,
  },
  watch: {
    myTasks(n, o) {
      if (n) {
        this.setTasks();
      }
    },
  },

  metaInfo: {
    title: "My Desk",
    meta: [{ vmid: "description", name: "description", content: "My Desk" }],
  },
};
</script>
<style scoped>
.desk-scroll {
  position: relative;
  width: 100%;
  z-index: 5;
  margin: auto;
  overflow: auto;
  height: calc(100vh - 78px);
}
</style>