<template>
  <form
    class="mt-3"
    style="padding-bottom: 200px"
    @submit.prevent="submitHandler"
    @keyup.enter="handleKeyPress"
    autocomplete="off"
  >
    <div class="formgrid p-fluid grid">
      <div
        class="field col-12 sm:col-6"
        v-if="!task_uid"
      >
        <label for="name">Task Type</label>
        <div class="mr-3">
          <Dropdown
            :showClear="true"
            :filter="true"
            v-if="task_uid == undefined"
            class="filterDD"
            optionLabel="type_name"
            optionValue="uid"
            dataKey="uid"
            placeholder="Select a Task Type"
            :options="task_types"
            v-model="task.task_type"
            @change="handleTaskTypeAndRate(task.task_type)"
          >
            <template #indicator>
              <i class="la la-filter" />
            </template>
          </Dropdown>

          <InputText
            v-else
            disabled
            v-model="task.task_type_name"
            class="input"
          />
        </div>
        <div
          class="error-validation-text pt-1"
          v-if="submitted && !$v.task.task_type.required"
        >
          Task Type is required
        </div>
      </div>

      <div :class="'field col-12 ' + (!task_uid ? 'sm:col-6' : '')">
        <label for="name">Task Name</label>
        <div class="p-inputgroup">
          <InputText
            placeHolder="Task Name (optional)"
            v-model="task.title"
          />
        </div>
      </div>
      <!-- <div class="field col-12 sm:col-6" v-if="task_uid">
        <label for="name">Estimated Time</label>
        <i
          class="las la-lock text-primary"
          v-if="task_uid"
          style="float: right"
        ></i>
        <div class="p-inputgroup flex align-items-center">
          <InputMask
            v-if="!task_uid"
            v-model="task.estimated_hours"
            mask="99:99"
            :disabled="task_uid"
          />
          <InputMask
            v-else-if="task.original_hours"
            v-model="task.original_hours"
            mask="99:99"
            :disabled="task_uid"
          />
          <InputMask
            v-else
            v-model="task.estimated_hours"
            :disabled="task_uid"
            mask="99:99"
          />
        </div>
        <div
          class="error-validation-text pt-1"
          v-if="submitted && !$v.task.estimated_hours.required"
        >
          Estimated Time is required
        </div>
      </div> -->
      <div class="flex align-items-center col-12 sm:col-6">
        <div class="field pl-0 col-12">
          <label>Rate / Hour</label>
          <i
            class="las la-lock text-primary cursor-pointer"
            v-if="task.is_approved != 0 || task.in_review != 0"
            style="float: right"
            v-tooltip.bottom="{
                        value: 'You can not edit the task rate on an active task.',
                      }"
          ></i>
          <div class="p-inputgroup align-items-center">
            <span
              v-if="job?.client?.currency?.description"
              class="p-inputgroup-addon"
            >{{ job?.client?.currency?.description }}</span>
            <InputNumber
              :disabled="task.is_approved == 1 || task.in_review == 1"
              v-model="task.rate"
              mode="decimal"
            />
          </div>
          <div
            class="error-validation-text pt-1"
            v-if="submitted && !$v.task.rate.required"
          >
            Rate is required
          </div>
        </div>
      </div>

      <div class="field col-12 sm:col-4">
        <label for="name">Estimated Time</label>
        <i
          class="las la-lock text-primary cursor-pointer"
          v-if="task.is_approved != 0 || task.in_review != 0"
          style="float: right"
          v-tooltip.bottom="{
                        value: 'You can not edit time on an active task.',
                      }"
        ></i>

        <!-- Use EstimatedTimePicker Component -->

        <TimePicker
          :time="!task.uid ? task.estimated_hours : task.original_hours"
          :disabled="task.is_approved == 1 || task.in_review == 1"
          label="Estimated Time"
          @update="
            task.original_hours
              ? (task.original_hours = $event)
              : (task.estimated_hours = $event)
          "
        />

        <div
          class="error-validation-text pt-1"
          v-if="submitted && !task.estimated_hours"
        >
          Estimated Time is required
        </div>
      </div>

      <div class="flex align-items-center col-12 sm:col-2">
        <div class="field col-12">
          <label>Billable</label>
          <div class="p-inputgroup align-items-center">
            <AActiveSwitch v-model="task.billable" />
          </div>
        </div>
      </div>

      <div
        class="field col-12 sm:col-6"
        v-if="!isJobCosting || task?.date_start?.date"
      >
        <label for="name">Start Date <template v-if="isJobCosting">(Optional)</template></label>
        <div>
          <Calendar
            id="basic"
            v-if="task?.date_start?.date !== undefined"
            v-model="task.date_start.date"
            :minDate="minimumDate"
            :maxDate="maximumDate"
            :showIcon="true"
            :selectOtherMonths="true"
            :showOtherMonths="true"
            placeholder="Select Date"
            dateFormat="yy-mm-dd"
          />
        </div>
        <!-- <div
          class="error-validation-text pt-1"
          v-if="submitted && !$v.task.date_start?.date.required"
        >
          Start date is required
        </div> -->
      </div>

      <div
        class="field col-12 sm:col-6"
        v-if="!isJobCosting || task?.date_due?.date"
      >
        <label for="name">Due Date<template v-if="isJobCosting">(Optional)</template></label>

        <div>
          <Calendar
            id="basic"
            v-if="task?.date_due?.date !== undefined"
            v-model="task.date_due.date"
            :maxDate="maximumDate"
            :minDate="minimumDate"
            :selectOtherMonths="true"
            :showOtherMonths="true"
            placeholder="Select Date"
            dateFormat="yy-mm-dd"
            :showIcon="true"
          />
        </div>
        <!-- <div
          class="error-validation-text pt-1"
          v-if="submitted && !$v.task.date_due.date.required"
        >
          Due date is required
        </div> -->
      </div>
      <div
        :class="'field-checkbox col-6 mb-0 cursor-pointer col-offset-6'"
        v-if="!isJobCosting"
      >
        <Checkbox
          id="binary"
          v-model="extendDate"
          :binary="true"
        />
        <label
          for="binary"
          class="font-light cursor-pointer"
        >Extend Job Due Date</label>
      </div>
      <div class="field col-12">
        <label for="name">Description</label>

        <Textarea
          ref="editor"
          v-model="task.description"
          :autoResize="true"
        ></Textarea>

        <!-- <Editor
          ref="editor"
          id="Description"
          :autoResize="true"
          v-model="task.description"
          editorStyle="height:150px"
        >
          <template #toolbar>
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button><button class="ql-strike"></button>
              <button class="ql-list" value="ordered"></button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-align"></button>
              <button class="ql-link"></button>
            </span> </template
        ></Editor> -->
      </div>
      <div
        class="field col-12 sm:col-6"
        v-if="task?.is_approved == 1"
      >
        <label for="name">Reviewed By</label>
        <div
          class="p-inputgroup"
          v-if="reviewerUsers.length > 0"
        >
          <Dropdown
            v-model="task.reviewed_by"
            :options="reviewerUsers"
            :disabled="task.in_review == 1"
            optionLabel="name"
            dataKey="uid"
            optionValue="uid"
            placeholder="Select review user"
            :showClear="true"
          >
          </Dropdown>
        </div>
        <div
          v-else
          class="p-inputgroup"
        >
          <Dropdown
            disabled
            placeholder="No reviewers assigned yet"
          />
        </div>
      </div>
      <!-- <div class="field col-12 sm:col-6">
        <div class="p-inputgroup">
          <Checkbox v-if="!isLoading" /><label
            >Add to job costing and Approve</label
          >
        </div>
      </div> -->
      <!-- <div class="field-checkbox col-12 sm:col-6 mb-0 cursor-pointer">
        <Checkbox
          v-if="!task_uid"
          id="binary"
          :binary="true"
          v-model="isApprove"
        />
        <label for="binary" class="font-light cursor-pointer" v-if="!task_uid"
          >Add to job costing and Approve</label
        >
      </div> -->
      <div
        v-if="task_uid && taskUsers?.lenght > 0"
        class="field col-12 sm:col-6 mt-2"
      >
        <label for="name">Users</label>
      </div>
    </div>
    <div
      v-for="u in taskUsers"
      class="formgrid p-fluid grid flex align-items-center"
      :key="u.uid"
    >
      <div :class="'col-6 field ' + (u.active !== 1 ? 'opacity-50' : '')">
        <div class="flex align-items-center">
          <ProgressAvatar
            :image="u.profile_picture"
            :user="u"
            :progress="u.progress_bar"
            :strokeColor="u.progress_bar >= 100 ? '#fe4d97' : '#6dd230'"
            innerStrokeColor="#ffffff"
            :radius="25"
            class="mr-2"
          ></ProgressAvatar>{{ u.name }} {{ u.surname }}
        </div>
      </div>
      <div :class="'col-6 field ' + (u.active !== 1 ? 'opacity-50' : '')">
        <div class="flex align-items-center justify-content-between">
          <div class="col text-right">
            <template v-if="u.estimated_hours">
              {{ !u.hours ? "00:00" : u.hours }} / {{ u.estimated_hours }}
              <div class="flex align-items-center mt-1">
                <div
                  class="surface-300 border-round overflow-hidden w-full"
                  style="height: 6px"
                >
                  <div
                    class="h-full"
                    :style="getProgressStyle(u)"
                  />
                </div>
              </div>
            </template>
          </div>
          <div class="col flex-grow-0">
            <AActiveSwitch
              @change="toggleUser(u.uid)"
              v-model="u.active"
            />
          </div>
        </div>
      </div>
    </div>
    <Button
      v-if="task?.is_approved == 1"
      label="Assign a User"
      class="p-button-text p-button-secondary"
      @click="
        $store.dispatch('setSelectedObject', task);
        $store.dispatch('setPopupDialog', 'UserSelect');
      "
      icon="las la-plus"
    />

    <div class="flex justify-content-between pt-5">
      <Button
        :disabled="!hasTaskRate"
        type="submit"
      ><span v-if="!task_uid">
          ADD <span v-if="!isApprove">DRAFT</span> TASK
        </span>
        <template v-else> SAVE</template></Button>
      <Button
        class="p-button-text p-button-secondary"
        @click="cancelEdit"
      >
        CANCEL
      </Button>
    </div>
  </form>
</template>
<script>
import TimePicker from "./TimePicker.vue";

import { required } from "vuelidate/lib/validators";
import {
  state,
  fetchTask,
  fetchTaskTypes,
  fetchUsersData,
} from "../../../services/data_service.js";
import { temp_state } from "../../../services/temp_state_service.js";
export default {
  name: "TaskForm",
  props: {
    taskProp: {
      type: Object,
    },
  },
  data() {
    return {
      task_uid: null,
      isApprove: false,
      hasTaskRate: false,
      submitted: false,
      extendDate: false,
      minimumDate: this.job?.date_start
        ? new Date(this.job.date_start.date)
        : null,
      maximumDate: this.job?.date_due ? new Date(this.job.date_due.date) : null,
      date_start: null,
      task: {
        title: null,
        rate: null,
        billable: 1,
        task_type: null,
        estimated_hours: null,
        original_hours: null,
        date_due: {
          date: null,
        },
        date_start: {
          date: null,
        },
      },
    };
  },
  validations: {
    task: {
      task_type: {
        required,
      },
      rate: {
        required,
      },
      estimated_hours: {
        required,
      },
      // date_start: {
      //   date: {
      //     required,
      //   },
      // },
      // date_due: {
      //   date: {
      //     required,
      //   },
      // },
    },
  },
  computed: {
    // task_uid() {
    //   return this.selectedObject ? this.selectedObject.uid : null;
    // },
    selectedObject() {
      return this.$store.getters.selectedObject;
    },
    job() {
      return state.job;
    },
    users() {
      return state.usersData;
    },
    task_types() {
      return state.taskTypes
        ? state.taskTypes.filter((task) => task.active == 1)
        : null;
    },
    itemGroup() {
      return state.itemGroup;
    },
    taskUsers() {
      return state.taskUsers;
    },
    maxDue() {
      var due = new Date(this.job.date_due.date);
      var due2 = this.date_due;
      var max = due;
      if (due2 < due) {
        max = due2;
      }
      return max;
    },
    maxDate() {
      return new Date(this.job.date_due.date);
    },
    reviewerUsers() {
      if (this.users.users) {
        let reviewers = this.users.users.filter(
          (a) => a.is_reviewer === 1 && a.active === 1
        );
        return reviewers;
      }
      return [];
    },
    isJobCosting() {
      return this.$route.name === "job--costing";
    },
  },
  beforeMount() {
    if (this.selectedObject && this.selectedObject.uid !== undefined) {
      this.task_uid = this.selectedObject.uid;
    } else {
      this.task_uid = this.selectedObject;
    }
  },
  created() {
    if (this.taskProp) {
      this.task = JSON.parse(JSON.stringify(this.taskProp));
    }
  },
  async mounted() {
    await this.loadData();
    if (this.task_uid) {
      state.itemGroup = null;
    }

    if (!this.task_uid) {
      this.isApprove = !this.isJobCosting;
    } else {
      this.isApprove = this.task.is_approved == 1 ? true : false;
    }
    if (this.task.title == "null") {
      this.task.title = "";
    }

    if (
      this.task.date_start?.date == null &&
      this.$route.name === "job--tasks"
    ) {
      this.task.date_start.date = this.$moment(new Date()).format("YYYY-MM-DD");
    } else if (!this.task.date_start?.date) {
      this.task.date_start = { date: null };
    }

    if (!this.task.date_due) {
      this.task.date_due = { date: null };
    }

    if (this.task.rate != null && this.task.rate != undefined) {
      this.hasTaskRate = true;
    }
  },
  methods: {
    handleKeyPress(event) {
      const editor = this.$refs.editor;
      if (editor && editor.$el.contains(event.target)) {
        // If "Enter" is pressed inside the editor, create a new line
        event.stopPropagation();
      } else {
        // Else, submit the form
        this.submitHandler();
      }
    },
    async loadData() {
      Promise.all([fetchTaskTypes(1), fetchUsersData()]);
    },
    handleTaskTypeAndRate(type) {
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/v1/tasktype/rate/" +
            this.job.client.uid +
            "/" +
            type,
          {}
        )
        .then((response) => {
          this.taskTypeRate = response.data.data;
          if (type) {
            let clientBillingTierRate;
            for (let i = 0; i < this.taskTypeRate.length; i++) {
              clientBillingTierRate = this.taskTypeRate[i].price;
            }
            if (clientBillingTierRate) {
              this.hasTaskRate = true;
              this.task.rate = parseInt(clientBillingTierRate);
            } else {
              this.task.rate = 0;
              this.$toast.add({
                severity: "warn",
                summary:
                  this.getTaskTypeName(type) +
                  " has no rate specified for this client's billing tier. Please add it under Account Settings > Tasks.",
                life: 5000,
              });
            }
            var taskType = this.task_types.find((a) => a.uid === type);
            if (taskType) {
              this.task.billable = taskType.billable;
              this.task.description = taskType.description;
              this.task.estimated_hours = taskType.default_hours;
            }
          }
        });
    },
    getDate() {
      const due = new Date(this.job?.date_due.date);
      const start = new Date(this.job?.date_start.date);

      if (this.extendDate) {
        this.maximumDate = null;
      } else {
        this.maximumDate = due;
      }

      this.minimumDate = start;
    },
    getProgressStyle(task) {
      var progress = task.progress_bar ? task.progress_bar : 0;
      if (progress >= 100) {
        return "background: #fe4d97;width:100%";
      } else {
        return "background: #6dd230;width:" + progress + "%";
      }
    },
    getUsers() {
      this.$store.dispatch("setSelectedObject", this.task.uid);
      this.$store.dispatch("setPopupDialog", "UserSelect");
    },
    getTaskTypeName(uid) {
      var name = null;
      this.task_types.forEach((type) => {
        if (type.uid == uid) {
          name = type.type_name;
        }
      });
      return name;
    },
    toggleUser(user_uid) {
      this.$axios
        .get(
          process.env.VUE_APP_ROOT_API +
            "/v1/tasks/" +
            this.task.uid +
            "/assign/" +
            user_uid,
          {}
        )
        .then((response) => {
          this.$store.dispatch("setSliderLoader", false);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    cancelEdit() {
      if (!this.task_uid) {
        this.clearSliderContent();
      } else {
        this.$emit("cancel");
      }
    },
    async submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        try {
          this.$store.dispatch("setSliderLoader", true);
          var form = new FormData();
          if (this.task.title == null) {
            this.task.title = "";
          }
          form.append("title", this.task.title);
          form.append("rate", this.task.rate);
          form.append("billable", this.task.billable);
          form.append("description", this.task.description);

          if (this.task.date_start?.date) {
            form.append("date_start", this.task.date_start?.date);
          }

          if (this.task.date_due?.date) {
            form.append("date_due", this.task.date_due.date);
          }
          form.append("is_approved", this.isApprove ? 1 : 0);

          form.append(
            "estimated_hours",
            this.task_uid == null
              ? this.task.estimated_hours
                ? this.task.estimated_hours
                : this.task.original_hours
              : this.task.original_hours
              ? this.task.original_hours
              : this.task.estimated_hours
          );
          form.append("extend_date", this.extendDate === true ? 1 : 0);
          if (this.task.reviewed_by) {
            form.append("reviewed_by", this.task.reviewed_by);
          }
          form.append("task_type", this.task.task_type);

          if (state.itemGroup) {
            form.append("job", this.job.uid);
            form.append("group", state.itemGroup);
          }
          var res = await this.$axios.post(
            process.env.VUE_APP_ROOT_API +
              "/v1/tasks" +
              (!this.task_uid ? "" : "/" + this.task_uid),
            form,
            {
              headers: { "Content-type": "application/x-www-form-urlencoded" },
            }
          );
          await fetchTask(res.data.data.uid);
          state.captured_times = [];
          state.notes = [];
          state.editTaskMode = false;
          state.itemGroup = null;
          this.$store.dispatch("setSelectedObject", res.data.data);
          this.$store.dispatch("setSliderView", "TaskSlider");
          if (this.task.title != null) {
            this.$toast.add({
              severity: "success",
              summary: "Task Saved",
              detail: this.task.title ?? "Task" + " has been saved",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "success",
              summary: "Task Saved",
              detail:
                this.getTaskTypeName(this.task.task_type) ??
                "Task" + " has been saved",
              life: 3000,
            });
          }
          this.$store.dispatch("setSliderLoader", false);
        } catch (error) {
          this.$store.dispatch("setSliderLoader", false);
          if (error.response && error.response.status === 401) {
            console.error("NOOOOOOOOO!");
          } else {
            // Handle error however you want
          }
          console.error(error);
        }
      }
    },
  },
  watch: {
    extendDate(n) {
      if (n === true) {
        this.maximumDate = null;
      } else {
        this.getDate();
      }
    },
    taskProp(n) {
      this.task = n;
    },
    "task.date_start.date"(newVal) {
      if (newVal) {
        const startDate = new Date(newVal);
        this.maximumDate = new Date(this.job?.date_due.date);
        if (
          this.task.date_due.date &&
          new Date(this.task.date_due.date) < startDate
        ) {
          // make start date the same as due date
          this.task.date_due.date = startDate;
        }
      }
    },
    "task.date_due.date"(newVal) {
      if (newVal) {
        const dueDate = new Date(newVal);
        this.minimumDate = new Date(this.job?.date_start.date);
        if (
          this.task.date_start.date &&
          new Date(this.task.date_start.date) > dueDate
        ) {
          this.task.date_start.date = dueDate;
        }
      }
    },
  },
  components: {
    TimePicker,
  },
};
</script>
<style lang="scss" scoped>
</style>