<template>
  <div class="card">
    <div class="flex flex-wrap sm:justify-content-between justify-content-center align-items-center pb-2">
      <div>
        <router-link
          v-for="r in path"
          :key="r.path"
          :to="{ path: r.route }"
        >
          <h6
            class="sm:pr-5"
            :style="'color: grey;' + getHeaderStyle(r.route)"
          >
            {{ r.header }}
          </h6>
        </router-link>
      </div>
      <div>
        <FilterSearchButton v-model="filters['global'].value" />
      </div>

    </div>

    <div>
      <transition name="slide-fade">
        <router-view />
      </transition>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
export default {
  data() {
    return {
      path: [
        {
          route: "/settings/templates",
          header: "Jobs",
        },
      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },

  methods: {
    getHeaderStyle(route) {
      if (this.$route.path == route) {
        return "font-weight: bold; color: #4D7CFE";
      }
    },
  },

  computed: {},
  name: "Templates",
};
</script>
